// NOTE: This is a legacy file. Don't add global functions like this.
$(function () {
  const facilityTypes = $('#new_physical_client_facility_type'),
      plans = $('#new_client_facility_network_form'),
      billingGroups = $('#new_physical_office'),
      clientFacilityCustomIdentifierElement = document.getElementById('client-facility-external-identifiers');

  if(clientFacilityCustomIdentifierElement){
    const clearCustomIdentifiersBtn = document.getElementsByClassName('clear-custom-identifiers-js')[0];

    window.resetProviderIDFields = (e) => {
      const formInputs = document.querySelectorAll('.custom-identifiers_form-group input');
      formInputs.forEach((element) => {
        element.value = element.getAttribute('value')
      });
    }

    clearCustomIdentifiersBtn.addEventListener('click', resetProviderIDFields, false);

  }

  billingGroups.hide();
  plans.hide();
  facilityTypes.hide();
});

window.toggleFormFacilities = e => {
  const $e = $(e);
  const $primaryBtn = $('.add-org-type-btn-js');
  const isExpanded = $primaryBtn.data('expanded');

  $primaryBtn
    .data('expanded', !isExpanded)
    .toggle();

  $('#new_physical_client_facility_type').slideToggle();
};

window.showDocumentationForm = function () {
  $('#newDocumentation').modal('show');
}
