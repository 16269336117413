import React from "react"
import PropTypes from "prop-types"
import Spinner from '../spinner/spinner'

/*
 * A simple typeahead that loads data from endpoint or list based on what is typed
 * Expected option format (secondary values are optional):
 * {
 *   label: 'label_string',
 *   value: 'value_of_any_type',
 *   secondary_values: [
 *     {
 *        value: 'value_of_any_type',
 *        key: 'key_name_as_string'
 *     }
 *   ]
 * }
 */

class TypeaheadFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownOptions: [],
      selectedOption: null,
      showDropdown: false,
      inputValue: this.props.value || '',
      showLoading: false
    }

    this.setValues = this.setValue.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.populateOptions = this.populateOptions.bind(this)
    this.getOptionsFromEndpoint = this.getOptionsFromEndpoint.bind(this)
    this.renderDropdown = this.renderDropdown.bind(this)
    this.updateSelected = this.updateSelected.bind(this)
    this.determineDropdownState = this.determineDropdownState.bind(this)
    this.removeLoading = this.removeLoading.bind(this)
    this.clearSelected = this.clearSelected.bind(this)
  }

  setValue(selectedOption) {
    let newData = [{value: selectedOption.value, key: this.props.formField.attribute}]
    if (selectedOption
        && selectedOption.hasOwnProperty('secondary_values')
        && selectedOption.secondary_values.length) {
          selectedOption.secondary_values.forEach(secondary_value => {
            newData = [
              ...newData,
              {key: secondary_value['key'], value: secondary_value['value']}
            ]
          })
    }
    this.props.onValueChange(newData)
  }

  clearSelected() {
    let emptyOption = {value: ''}
    if (this.props.formField['secondary_option'] && this.props.formField['secondary_option'].length) {
      emptyOption.secondary_values = [{
            value: '',
            key: this.props.formField['secondary_option']
          }]
    }
    this.setState({selectedOption: null}, this.setValue(emptyOption))
  }

  removeLoading() {
    this.setState({showLoading: false})
  }

  determineDropdownState(showDropdown, searchValue) {
    if (showDropdown) {
      this.populateOptions(searchValue)
    } else {
      this.setState({dropdownOptions: []}, this.removeLoading)
    }
  }

  onTextChange(searchValue) {
    this.clearSelected()
    let isAcceptableLength = searchValue.length > 2
    this.setState(
      {inputValue: searchValue, showDropdown: isAcceptableLength, showLoading: true},
      this.delayDropdown(isAcceptableLength, searchValue)
    )
  }

  delayDropdown = (isAcceptableLength, searchValue) => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.determineDropdownState(isAcceptableLength, searchValue);
    }, 2000);
  }

  updateSelected(selectedOption) {
    this.setState({inputValue: selectedOption.label, selectedOption: selectedOption, showDropdown: false}, this.setValue(selectedOption))
  }

  getOptionsFromEndpoint(searchValue) {
    let endpoint = `${window.MAIN}/provider_applications/${this.props.application.id}/${this.props.formField['options_path']}?query=${searchValue}`
    fetch(endpoint, {
      method: 'GET',
      headers: {'Content-Type': 'application/json; charset=utf-8'}
    }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          this.setState({dropdownOptions: data}, this.removeLoading)
        })
      }
    })
  }

  populateOptions(searchValue) {
    if (this.props.formField['options_path']) {
      this.getOptionsFromEndpoint(searchValue)
    } else {
      this.setState({dropdownOptions: this.props.formField.options.filter(option => {
        return option.value.includes(searchValue)
      })}, this.removeLoading)
    }
  }

  renderDropdown() {
    if (this.state.dropdownOptions.length === 0) return <div>No results found</div>
    return this.state.dropdownOptions.map((option, index) => {
      return <div key={index} className="brand-typeahead-option" onClick={()=>{this.updateSelected(option)}}>{option.label}</div>
    })
  }

  render() {
    return (
        <div className="brand-typeahead-input">
          <input
            type="text"
            className={`brand-input w-100 ${this.props.isInvalid ? 'brand-invalid' : ''}`}
            value={this.state.inputValue}
            onChange={(e) => this.onTextChange(e.target.value)} />
          <div className={`brand-typeahead-dropdown ${this.state.showDropdown ? '' : 'dn'}`}>
            {this.state.showLoading
              ? <div className="w-100 h4 flex flex items-center">
                <Spinner className="maa" />
              </div>
              : this.renderDropdown()
            }
          </div>
        </div>
    )
  }
}
TypeaheadFieldComponent.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  formField: PropTypes.object,
  application: PropTypes.object,
  isInvalid: PropTypes.bool
}
TypeaheadFieldComponent.defaultProps = {
  value: '',
  onValueChange: () => {},
  formField: {},
  application: {},
  isInvalid: false
}
export default TypeaheadFieldComponent
