window.setText = (element, text) => {
  if (document.body.innerText) {
    element.innerText = text;
  } else {
    element.textContent = text;
  }
}

window.chartError = (element, message) => {
  setText(element, "Error Loading Chart: " + message);
  element.style.color = "#ff0000";
}

window.exportCrossfilterDataToCSV = output => {
  var exportData = output.top(Infinity)
  var objArray = JSON.stringify(exportData);

  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  var header = Object.keys(array[0])

  str += header + '\r\n';

  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line != '') line += ','

      line += array[i][index];
    }

    str += line + '\r\n';
  }
  window.open("data:text/csv;charset=utf-8," + escape(str));
};
