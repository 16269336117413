import React from "react"
import PropTypes from "prop-types"
import Spinner from '../spinner/spinner'
import ViewComponent from './ViewComponent'
import EditComponent from './EditComponent'
import * as _ from "lodash"

/*
 * This is a generic component that can switch between viewing and editing data
 * Originally created for the Provider Application feature, but will eventually
 * be usable elsewhere.
 */

class ViewEditForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditModeOn: (props.format === 'list' && props.isNewListItem) ? true : false,
      isSaving: false,
      isError: false,
      formValues: this.getFormValues(),
      originalFormValues: this.getFormValues(),
      invalidFields: {},
      hasClearedOtherSection: false
    }
    this.getFormValues = this.getFormValues.bind(this)
    this.toggleEditMode = this.toggleEditMode.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onDeleteRow = this.onDeleteRow.bind(this)
    this.buildUpdatedApplication = this.buildUpdatedApplication.bind(this)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (this.state.isEditModeOn) {
      e.preventDefault()
      e.returnValue = true
    }
  }

  getFormValues() {
    let formValues = {}
    this.props.formData.forEach(column => {
      column.fields.forEach(field => {
        if (this.props.format === 'list') {
          formValues[field.attribute] = this.props.formList[this.props.index][field.attribute] || ''
        } else {
          formValues[field.attribute] = this.props.application[this.props.sectionName][field.attribute] || ''
        }
      })
    })
    return formValues
  }

  toggleEditMode() {
    this.setState({isEditModeOn: !this.state.isEditModeOn})
  }

  handleValueChange(newData) {
    this.setState(prevState => {
      let formValues = Object.assign({}, prevState.formValues)
      newData.forEach(item => {
        formValues[item['key']] = item['value']
      })
      return {formValues}
    })
  }

  buildUpdatedApplication() {
    const appObj = {provider_id: this.props.application.provider_id}

    if (this.props.format === 'list') {
      let newFormList = this.props.formList
      newFormList[this.props.index] = this.state.formValues
      appObj[this.props.sectionName] = {}
      if (this.props.subsectionName) {
        appObj[this.props.sectionName][this.props.subsectionName] = {}
        appObj[this.props.sectionName][this.props.subsectionName][this.props.listName] = newFormList
      } else {
        appObj[this.props.sectionName][this.props.listName] = newFormList
      }
    } else {
      appObj[this.props.sectionName] = {}
      Object.keys(this.state.formValues).forEach(key => {
        if (this.state.formValues[key] != this.state.originalFormValues[key]) {
          appObj[this.props.sectionName][key] = this.state.formValues[key]
        }
      })
    }

    /* Account for the value of one field change having to clear out a different section of the form
     * Each item of the clearSectionOnFieldChangeConfigs list should look like the following:
     * {
     *   fieldToWatch: String - field name,
     *   sectionToClear: String - section name,
     *   listToClear: String - list name
     * }
     */
    if (this.props.clearSectionOnFieldChangeConfigs.length) {
      this.props.clearSectionOnFieldChangeConfigs.forEach(config => {
        if (_.get(config, 'fieldToWatch')
            && _.get(config, 'sectionToClear')
            && _.get(config, 'listToClear')
            && appObj[this.props.sectionName].hasOwnProperty(_.get(config, 'fieldToWatch'))) {
          appObj[_.get(config, 'sectionToClear')] = {}
          appObj[_.get(config, 'sectionToClear')][_.get(config, 'listToClear')] = []
          this.setState({hasClearedOtherSection: true})
        }
      })
    }
    return appObj
  }

  onSave() {
    const endpoint = `${window.MAIN}${this.props.patchEndpoint}.json`
    this.setState({isSaving: true})
    fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify({application: this.buildUpdatedApplication()}),
      headers: {'Content-Type': 'application/json; charset=utf-8'}
    }).then(response => {
      const isValid = response.ok
      const status = response.status
      response.json().then(
        data => {
          if (isValid) {
            // Use new formValues as source of truth
            this.setState({
              originalFormValues: this.state.formValues,
              invalidFields: {},
              isSaving: false,
              isEditModeOn: false
            }, () => {
              if (this.props.format === 'list') {
                this.props.finishAddingNewItem(data)
              } else {
                if (this.props.isSinglePageForm) this.props.updateApplicationObject(data)
              }
              if (this.props.isSinglePageForm && this.state.hasClearedOtherSection) location.reload()
            })
          } else if (status == '500') {
            this.setState({isError: true, errorData: 'Internal Server Error', isSaving: false})
          } else {
            if (this.props.format === 'list') {
              this.setState({invalidFields: data.data[this.props.listName][this.props.index].fields})
            } else {
              this.setState({invalidFields: data.data[this.props.sectionName][0].fields})
              if (!this.props.isSinglePageForm) window.scrollTo(0, 0)
            }
            this.setState({isSaving: false})
          }
        },
        error => {
          this.setState({isError: true, errorData: error, isSaving: false})
        }
      )
    })
  }

  onCancel() {
    if (this.props.format === 'list' && this.props.isNewListItem) {
      this.setState({isEditModeOn: false}, this.props.removeNewListItem)
    } else {
      this.setState({
        formValues: this.state.originalFormValues,
        invalidFields: {},
        isEditModeOn: false
      })
    }
  }

  onDeleteRow() {
    const endpoint = `${window.MAIN}${this.props.patchEndpoint}.json`
    this.setState({isSaving: true})
    let appObj = {}
    let newFormList = this.props.formList
    newFormList.splice(this.props.index,1)
    if (newFormList.length === 0) newFormList = this.props.allowBlankArrayWhenEmpty ? [] : [{}]
    appObj[this.props.sectionName] = {}
    if (this.props.subsectionName) {
      appObj[this.props.sectionName][this.props.subsectionName] = {}
      appObj[this.props.sectionName][this.props.subsectionName][this.props.listName] = newFormList
    } else {
      appObj[this.props.sectionName][this.props.listName] = newFormList
    }
    fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify({application: appObj}),
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      const isValid = response.ok
      const status = response.status
      response.json().then(
        data => {
          this.setState({isSaving: false, isEditModeOn: false})
          if (isValid) {
            this.props.removeItemFromList(data)
            this.props.updateFormList(newFormList)
          } else if (status == '500') {
            this.setState({isError: true, errorData: 'Internal Server Error', isSaving: false})
          } else {
            this.setState({isError: true, errorData: data, isSaving: false})
          }
        },
        error => {
          this.setState({isError: true, errorData: error, isSaving: false})
        }
      )
    })
  }

  render() {
    return (
      <div className={`relative ${this.props.className}`}>
        {
          this.state.isError
            ? <div className="alert">
              { this.state.errorData }
            </div>
            : null
        }
        {
          this.state.isSaving
          ? <div className="w-100 h-100 absolute flex items-center">
              <Spinner className="maa" />
            </div>
          : null
        }
        <h4 className="mt0">{this.props.title}</h4>
        {
          this.state.isEditModeOn
          ? <EditComponent
              className={this.state.isSaving ? 'o-50 disable-clicks' : ''}
              formData={this.props.formData}
              formValues={this.state.formValues}
              handleValueChange={this.handleValueChange}
              application={this.props.application}
              onSave={this.onSave}
              onCancel={this.onCancel}
              invalidFields={this.state.invalidFields}
              format={this.props.format}
              isSinglePageForm={this.props.isSinglePageForm} />
          : <ViewComponent
              className={this.state.isSaving ? 'o-50 disable-clicks' : ''}
              formData={this.props.formData}
              formValues={this.state.originalFormValues}
              toggleEditMode={this.toggleEditMode}
              onDeleteRow={this.onDeleteRow}
              nextSectionURL={this.props.nextSectionURL}
              format={this.props.format}
              isSinglePageForm={this.props.isSinglePageForm}
              disableEditMode={this.props.disableEditMode} />
        }
      </div>
    )
  }
}

ViewEditForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  formData: PropTypes.array,
  application: PropTypes.object,
  sectionName: PropTypes.string,
  subsectionName: PropTypes.string,
  listName: PropTypes.string,
  formList: PropTypes.array,
  updateFormList: PropTypes.func,
  isNewListItem: PropTypes.bool,
  finishAddingNewItem: PropTypes.func,
  removeItemFromList: PropTypes.func,
  removeNewListItem: PropTypes.func,
  nextSectionURL: PropTypes.string,
  patchEndpoint: PropTypes.string,
  format: PropTypes.string,
  index: PropTypes.number,
  isSinglePageForm: PropTypes.bool,
  updateApplicationObject: PropTypes.func,
  clearSectionOnFieldChangeConfigs: PropTypes.array,
  disableEditMode: PropTypes.bool,
  allowBlankArrayWhenEmpty: PropTypes.bool
}

ViewEditForm.defaultProps = {
  className: "",
  title: "",
  formData: [],
  application: {},
  sectionName: "",
  subsectionName: "",
  listName: "",
  formList: [],
  updateFormList: () => {},
  isNewListItem: false,
  finishAddingNewItem: () => {},
  removeItemFromList: () => {},
  removeNewListItem: () => {},
  nextSectionURL: null,
  patchEndpoint: "",
  format: "page",
  index: null,
  isSinglePageForm: false,
  updateApplicationObject: () => {},
  clearSectionOnFieldChangeConfigs: [],
  disableEditMode: false,
  allowBlankArrayWhenEmpty: false
}

export default ViewEditForm
