import React from 'react';

class MltcUploader extends React.Component {

  getInitialState() {
    return {
      current_client: '',
      attachment: '',
      file: '',
      owner_id: '',
      form: '',
      doc_filename: '',
      data_uri: null,
      original_filename: ''
    }
  }

  handleFile(e) {
    var self = this
    var reader = new FileReader()
    var file = e.target.files[0]
    reader.onload = function(upload) {
      self.setState({
        data_uri: upload.target.result.split(',')[1],
        original_filename: file.name,
        file_content_type: file.type,
        file_size: file.size
      })
    }
    reader.readAsDataURL(file)
  }

  handleSubmit(e) {

    var json =
    { documentation:
      {
        client_id: this.props.facility_id,
        owner_id: this.props.current_client_id,
        form: {
          file: this.state.data_uri,
          original_filename: this.state.original_filename,
          content_type: this.state.file_content_type
        },
        doc_filename: this.refs.doc_filename.value
      }
    }

    $.ajax({
      url: this.props.url,
      dataType: "json",
      type: "POST",
      data: JSON.stringify(json),
      processData: false,
      contentType: 'application/json',
      success: function(data) {
        location.reload()
      }.bind(this),
      error: function(xhr, status, err) {
        console.log("error creating post", xhr, status, err)
      }.bind(this)
    })
  }

  addUpload(upload) {
    var updatedUploads = this.state.uploads.slice()
    updatedUploads.push(upload)
    this.setState({uploads: updatedUploads})
  }

  render() {
    return (
      <form className="form-horizontal" onSubmit={this.handleSubmit} encType="multipart/form-data">
      <div className="control-group file required document_form">
      <label className="file required control-label"><abbr title="required">*</abbr> Upload File</label>
      <div className="controls">
      <input className="file required" type="file" onChange={this.handleFile} name="document[form]" id="attachment" ref="attachment" />
      <label className="file_upload_label">Type of Document</label>
      <select ref="doc_filename" className="select required span10" name="document[doc_filename]" id="document_doc_filename">
      <option value="">Select a document:</option>
      <option value="CMS - NPI Assignment Letter">CMS - NPI Assignment Letter</option>
      <option value="NYS DOT - Operating License">NYS DOT - Operating License</option>
      <option value="DMV - Affidavit of Compliance">DMV - Affidavit of Compliance</option>
      <option value="NYC - Taxi and Limousine Commission (TLC) - Paratransit Base">NYC - Taxi and Limousine Commission (TLC) - Paratransit Base</option>
      <option value="Auto Liability Insurance">Auto Liability Insurance</option>
      <option value="General Liability Insurance">General Liability Insurance</option>
      <option value="Professional Liability Insurance">Professional Liability Insurance</option>
      <option value="Worker's Compensation Insurance">Workers Compensation Insurance</option>
      <option value="IRS - Assignment Letter">IRS - Assignment Letter</option>
      <option value="Medicaid - Approval Letter">Medicaid - Approval Letter</option>
      <option value="Medicare - Award Letter">Medicare - Award Letter</option>
      <option value="W9">W9</option>
      <option value="OMIG - Provider Compliance">OMIG - Provider Compliance</option>
      <option value="Application">Application</option>
      <option value="NYC - Taxi and Limousine Commission - Community Care Services Approval.">NYC - Taxi and Limousine Commission - Community Care Services Approval.</option>
      <option value="NYS - Ambulance Service Certification">NYS - Ambulance Service Certification</option>
      <option value="NYS - Management Operating Certificate">NYS - Management Operating Certificate</option>
      <option value="Facility Owners List">Facility Owners List</option>
      <option value="ADA Attestation">ADA Attestation</option>
      <option value="NYS Division of License - Article 37A">NYS Division of License - Article 37A</option>
      <option value="Professional Requirement to Practice Evidence (Degree)">Professional Requirement to Practice Evidence (Degree)</option>
      <option value="NYS - Education Dept. License/Registration">NYS - Education Dept. License/Registration</option>
      <option value="NYS Office of Professions - Registration Certification">NYS Office of Professions - Registration Certification</option>
      <option value="DEA or NYS - Controlled Substance License">DEA or NYS - Controlled Substance License</option>
      <option value="Dept. of Consumer Affairs - Dealer Devices License">Dept. of Consumer Affairs - Dealer Devices License</option>
      <option value="Current Respiratory Therapist Staff License/Registration">Current Respiratory Therapist Staff License/Registration</option>
      <option value="Participating Practitioners List">Participating Practitioners List</option>
      <option value="Orthotics and Prosthetics Certifications">Orthotics and Prosthetics Certifications</option>
      <option value="NYC Dept. of Health and Hygiene - Certifiate">NYC Dept. of Health and Hygiene - Certifiate</option>
      <option value="NYS Dept. of Health - Home Care License ">NYS Dept. of Health - Home Care License </option>
      <option value="NYS Dept. of Health - Training Sites Approval Letter">NYS Dept. of Health - Training Sites Approval Letter</option>
      <option value="Title XIX Training Plan">Title XIX Training Plan</option>
      <option value="DOH or Joint Commission - Executive Summary">DOH or Joint Commission - Executive Summary</option>
      <option value="Wage Parity Attestation">Wage Parity Attestation</option>
      <option value="NYS - Ophthalmic Dispenser License">NYS - Ophthalmic Dispenser License</option>
      <option value="Low Vision Certificate">Low Vision Certificate</option>
      <option value="Contact Lens Certificate (if applicable)">Contact Lens Certificate (if applicable)</option>
      <option value="National Diabetes Educator Certificate">National Diabetes Educator Certificate</option>
      <option value="Other">Other</option>
      </select>
      <input value={this.props.current_client_id} ref="current_client" type="hidden" name="document[owner_id]" id="document_owner_id" />
      </div>
      </div>
      <br />
      <div className="form-actions">
      <div className="row-fluid">
      <div className="span6">
      <button aria-hidden="true" className="btn pull-left" data-dismiss="modal">Cancel</button>
      </div>
      <div className="span6">
      <input type="submit" value="Upload Documentation" id="upload_doc_button" className="btn btn-info pull-right" />
      </div>
      </div>
      </div>
      </form>
    )
  }
}

MltcUploader.propTypes = {
  url: PropTypes.string,
  current_client_id: PropTypes.number,
  current_user_id: PropTypes.number,
  facility_id: PropTypes.string
}
