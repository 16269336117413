import React from "react"
import moment from 'moment'
import IconEditSVG from '../../../../../assets/svg/icon-edit-modify.svg'
import IconTrashSVG from '../../../../../assets/svg/icon-delete.svg'


const getOptionLabel = (targetValue, formField) => {
  let option = formField.options.find(option => option.value === targetValue)
  return option ? option.label : ''
}
const getBasicDisplay = (targetValue, formField) => formField.obfuscation ? formField.obfuscation : targetValue

const displayValue = (formValues, formField) => {
  if (formValues[formField.attribute] || formValues[formField.attribute].length) {
    return <span className="mw5">
        {
          (() => {
            switch (formField.type) {
              case 'multi_select':
                return formValues[formField.attribute].join(', ')
              case 'date':
                return formValues[formField.attribute].length ? moment(formValues[formField.attribute]).format('MM/DD/YYYY') : ''
              case 'currency':
                return <span>${getBasicDisplay(formValues[formField.attribute], formField).toLocaleString()}</span>
              case 'hidden':
                return null
              default:
                return formField.display_option_label ? getOptionLabel(formValues[formField.attribute], formField) : getBasicDisplay(formValues[formField.attribute], formField)
            }
          })()
        }
      </span>
  } else {
    return formField.required ? <span className='brand-red i'>Required</span> : <span className='i'>Optional</span>
  }
}

const displayButtons = (toggleEditMode, onDeleteRow, nextSectionURL, format, isSinglePageForm) => {
  switch (format) {
    case 'list':
      return <div className="flex justify-end items-center mt3">
        <span className="mr2 flex items-start icon pointer icon-andros-tide" onClick={toggleEditMode}>
          <IconEditSVG />Edit
        </span>
        <span className="flex items-start pointer icon  icon-trash icon-danger" onClick={onDeleteRow}>
          <IconTrashSVG />Delete
        </span>
      </div>
    case 'page':
      return <div className="flex justify-end items-center">
        <button className="app-btn pa2" onClick={toggleEditMode}>Edit</button>
        {
          isSinglePageForm
          ? null
          : <button className="app-btn-secondary ml2 pv2 ph3">
            <a href={nextSectionURL}>Next</a>
          </button>
        }
      </div>
  }
}

/*
 * This component displays the values in a non-editable way for the ViesEditForm
 */

const ViewComponent = ({
  className = '',
  formData = [],
  formValues = {},
  toggleEditMode = () => {},
  onDeleteRow = () => {},
  nextSectionURL = '',
  format = 'page',
  isSinglePageForm = false,
  disableEditMode = false
}) => {

  return(
    <div className={`${className} ${format === 'list' ? 'flex justify-between flex-column flex-wrap' : ''}`}>
      <div className=''>
        {
          formData.map((formColumn, columnIndex) => {
            return (
              <div key={columnIndex}>
                {formColumn.column_name ? <h5 className=''>{formColumn.column_name}</h5> : ''}
                <div className={`grid ${format === 'page' ? 'grid-columns4 grid-gap1 mb3' : 'grid-columns4 grid-gap1 mb3'}`}>

                  {
                    formColumn.fields.map((formField, fieldIndex) => {
                      return formField.type === 'hidden'
                      ? null
                      : <div className='mb3 mr3 flex flex-column items-start' key={fieldIndex}>
                          <span className='form-header'><b>{formField.label}</b></span>
                          {displayValue(formValues, formField)}
                        </div>
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      {disableEditMode ? null : displayButtons(toggleEditMode, onDeleteRow, nextSectionURL, format, isSinglePageForm)}
    </div>
  )
}

export default ViewComponent
