const profileLinkMap = {
  'npdb': 'npdb',
  'oig': 'oig',
  'omig': 'oig',
  'sam': 'oig',
  'state license': 'licenses',
  'dea': 'dea',
  'board cert': 'board_certification'
}

export default profileLinkMap