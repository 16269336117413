import React from "react"
import PropTypes from "prop-types"
import * as _ from "lodash"
import Spinner from '../spinner/spinner'
import FlagSVG from '../../../../../assets/svg/icon-issue-flagged.svg'
// import FlagSVG from '../../../javascripts/components/svgs/iconFlag'

/*
 * Component for handling the special case of Disclosure Questions on the Provider Application
 */

class DisclosureForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disclosureQuestions: this.initializeDisclosureQuestions(),
      applicationDisclosures: this.getApplicationDisclosures(),
      selectedFormType: this.initializeFormType(),
      isSaving: false,
      displayError: this.hasVerificationErrors(this.props.application),
      errorMessage: this.hasVerificationErrors(this.props.application)
        ? "Unable to verify disclosures. Please make sure that all questions have been answered, and explanations are provided for answers of Yes. If problem still persists, please contact support."
        : ""
    }
    this.getApplicationDisclosures = this.getApplicationDisclosures.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.getQuestionProperty = this.getQuestionProperty.bind(this)
    this.hasVerificationErrors = this.hasVerificationErrors.bind(this)
    this.formTypeSelector = this.formTypeSelector.bind(this)
    this.updateDisclosureForm = this.updateDisclosureForm.bind(this)
    this.generateNewListOfQuestions = this.generateNewListOfQuestions.bind(this)
    this.generateNewListOfDisclosures = this.generateNewListOfDisclosures.bind(this)
    this.initializeFormType = this.initializeFormType.bind(this)
    this.initializeDisclosureQuestions = this.initializeDisclosureQuestions.bind(this)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.doesAnswerMeetExpected = this.doesAnswerMeetExpected.bind(this)
    this.convertFlagToBoolString = this.convertFlagToBoolString.bind(this)
  }

  initializeFormType() {
    if (!this.props.isDataEntryUser) return "CAQH"
    return this.props.application['disclosure']['form_type'] ? this.props.application['disclosure']['form_type'] : null
  }

  initializeDisclosureQuestions() {
    if (!this.props.isDataEntryUser) return this.props.disclosureQuestions
    return this.props.application['disclosure']['form_type'] ? this.props.disclosureQuestions : []
  }

  getApplicationDisclosures() {
    if (_.get(this.props.application, 'disclosure.disclosure_questions')
          && _.get(this.props.application, 'disclosure.disclosure_questions').length) {
      // If questions have already been filled out and saved, get list from application
      return _.get(this.props.application, 'disclosure.disclosure_questions')
    } else {
      // If questions haven't been filled out yet, set to blank array or CAQH list depending on user role
      if (this.props.isDataEntryUser) return []
      return this.generateNewListOfDisclosures(this.props.disclosureQuestions)
    }
  }

  generateNewListOfQuestions(disclosureQuestions = []) {
    // If user is data entry, return disclosure questions specified in arguments
    if (this.props.isDataEntryUser) return disclosureQuestions
    // If user is provider, use CAQH questions from prop
    return this.props.disclosureQuestions
  }

  generateNewListOfDisclosures(disclosureQuestions = []) {
    return disclosureQuestions.map(dq => {
      return {
        disclosure_question_id: dq.id,
        answer_flag: null,
        explanation: "",
        summary: dq.summary
      }
    })
  }

  updateDisclosureForm(formType) {
    this.setState({isSaving: true})
    let endpoint = `${window.MAIN}/provider_applications/${this.props.application['id']}/disclosure_questions_list?form=${formType}`
    fetch(endpoint, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      const isValid = response.ok
      if (isValid) {
        response.json().then(data => {
          this.setState({
            disclosureQuestions: this.generateNewListOfQuestions(data),
            applicationDisclosures: this.generateNewListOfDisclosures(data),
            selectedFormType: formType,
            isSaving: false
          })
        })
      } else {
        this.setState({
          isSaving: false,
          displayError: true,
          errorMessage: "There was a problem loading the selected form. If this problem persists, please contact support."
        })
      }
    })
    return
  }

  hasVerificationErrors(appObj) {
    if (_.get(appObj, 'verification.disclosure_questions') && _.get(appObj, 'verification.disclosure_questions.is_verified') === false) {
      return true
    }
    return false
  }

  onFieldChange(value, fieldName, disclosure_question) {
    let newApplicationDisclosures = this.state.applicationDisclosures
    newApplicationDisclosures.forEach(dq => {
      if (dq.disclosure_question_id === disclosure_question.id) {
        dq[fieldName] = value
        // Answering with an expected answer should nullify explanation if one exists
        if (this.doesAnswerMeetExpected(disclosure_question.expected_answer_flag, dq.answer_flag)) {
          dq.explanation = ''
        }
      }
    })
    this.setState({applicationDisclosures: newApplicationDisclosures})
  }

  onSave() {
    this.setState({isSaving: true})
    const endpoint = `${window.MAIN}${this.props.patchEndpoint}.json`
    const appObj = {
      disclosure: {
        form_type: this.state.selectedFormType,
        disclosure_questions: this.state.applicationDisclosures
      }
    }
    fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify({application: appObj}),
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      const isValid = response.ok
      response.json().then(data => {
        if (isValid) {
          // If verification errors are found, keep user on same page and display error message
          if (this.hasVerificationErrors(data)) {
            this.setState({
              isSaving: false,
              displayError: true,
              errorMessage: "Unable to verify disclosures. Please make sure that all questions have been answered, and explanations are provided when prompted. If problem still persists, please contact support."
            })
            this.scrollToTop()
          } else if (this.props.isSinglePageForm) {
            let responseValues = data['disclosure']['disclosure_questions']
            this.setState({
              applicationDisclosures: responseValues,
              isSaving: false,
              displayError: false
            })
            if (this.props.isSinglePageForm) this.props.updateApplicationObject(data)
          } else {
            if (this.props.nextSectionURL) window.location = this.props.nextSectionURL
          }
        } else {
          this.setState({
            isSaving: false,
            displayError: true,
            errorMessage: "Invalid submission detected. Please make sure that all questions have been answered, and explanations are provided when prompted."
          })
          this.scrollToTop()
        }
      })
    })
  }

  scrollToTop() {
    if (this.props.containingElementId) {
      // Scroll to top of element, then account for site header
      document.getElementById(this.props.containingElementId).scrollIntoView()
      window.scrollBy(0, -Math.abs($('.navbar').height() + 10))
    } else {
      window.scrollTo(0, 0)
    }
  }

  getQuestionProperty(disclosure_question_id, propertyName) {
    let currentQuestion = this.state.applicationDisclosures.find(dq =>
      dq.disclosure_question_id === disclosure_question_id)
    return currentQuestion ? currentQuestion[propertyName] : null
  }

  formTypeSelector() {
    if (!this.props.isDataEntryUser) return
    return (<div>
      <div className="flex">
        <label className="mr3">Disclosure Form Type:</label>
        <div className="brand-select-container w5 mb3">
          <select className="brand-input w5" value={this.state.selectedFormType || ''} onChange={(e) => this.updateDisclosureForm(e.target.value)}>
            <option value="">Please select a disclosure form</option>
            {this.props.disclosureFormTypes.map(formType => <option value={formType}>{formType}</option>)}
          </select>
        </div>
      </div>
      <p><span class="flex icon icon-flag brand-yellow mr1"><FlagSVG /></span> Select the 'CAQH' form type for Maryland, Missouri, Ohio, or Oklahoma.</p>
    </div>)
  }

  convertFlagToBoolString(flag) {
    if ([1, "1"].includes(flag)) {
      return "true"
    } else if ([0, "0"].includes(flag)) {
      return "false"
    }
    return null
  }

  doesAnswerMeetExpected(expectedAnswerFlag, givenAnswer) {
    // Return true if no answer has been given yet, as there is nothing to check
    if (givenAnswer == null) return true
    let expectedAnswer = this.convertFlagToBoolString(expectedAnswerFlag)
    return givenAnswer === expectedAnswer
  }

  render () {
    return (
      <form
        className={`relative ${this.props.className}`}
        onSubmit={(e) => {
          e.stopPropagation()
          e.preventDefault()
          this.onSave()
        }}
      >
        { this.state.isSaving
          ? <div className="w-100 h-100 absolute flex items-center">
              <Spinner className="maa" />
            </div>
          : null
        }
        <h4 className="mt0">Disclosure Questions</h4>
        {
          this.state.displayError
          ? <p className="brand-red">{this.state.errorMessage}</p>
          : null
        }
        {this.formTypeSelector()}
        <div className={this.state.isSaving ? 'o-50 disable-clicks' : ''}>
          {
            this.state.disclosureQuestions.map((dq, index) => (
              <div className="mb4" key={index}>
                <p>{dq.question}</p>
                <div className="mb3">
                  <label className="di">
                    <input
                      type="radio"
                      value="true"
                      onChange={(e) => this.onFieldChange(e.target.value, 'answer_flag', dq)}
                      checked={this.getQuestionProperty(dq.id, 'answer_flag') === 'true'}
                      disabled={this.props.disableEditMode}
                    />
                    <span className="ml2 mr3">Yes</span>
                  </label>
                  <label className="di">
                    <input
                      type="radio"
                      value="false"
                      onChange={(e) => this.onFieldChange(e.target.value, 'answer_flag', dq)}
                      checked={this.getQuestionProperty(dq.id, 'answer_flag') === 'false'}
                      disabled={this.props.disableEditMode}
                    />
                    <span className="ml2">No</span>
                  </label>
                </div>
                <div className={`h3 collapsable ${this.doesAnswerMeetExpected(dq.expected_answer_flag, this.getQuestionProperty(dq.id, 'answer_flag')) ? 'collapsed' : ''}`}>
                  {
                    this.props.disableEditMode
                    ? <p>Explanation: <span className="black">{this.getQuestionProperty(dq.id, 'explanation')}</span></p>
                    : <textarea
                      className={`w-100 h-100 ${(this.state.displayError && !this.doesAnswerMeetExpected(dq.expected_answer_flag, this.getQuestionProperty(dq.id, 'answer_flag')) && !this.getQuestionProperty(dq.id, 'explanation')) ? 'brand-invalid' : ''}`}
                      placeholder="Please explain answer..."
                      value={this.getQuestionProperty(dq.id, 'explanation') || ''}
                      onChange={(e) => this.onFieldChange(e.target.value, 'explanation', dq)}>
                    </textarea>
                  }
                </div>
              </div>
            ))
          }
          <div className="flex justify-end">
            {
              !this.props.disableEditMode
              ? <input type="submit" value={this.props.isSinglePageForm ? 'Save Disclosure Questions' : 'Save'} className="app-btn pa2" />
              : null
            }
            {
              (this.props.disableEditMode && !this.props.isSinglePageForm)
              ? <a className="app-btn-secondary ml2 pv2 ph3" type="button" href={this.props.nextSectionURL}>Next</a>
              : null
            }
          </div>
        </div>
      </form>
    )
  }
}

DisclosureForm.propTypes = {
  className: PropTypes.string,
  disclosureQuestions: PropTypes.array,
  application: PropTypes.object,
  patchEndpoint: PropTypes.string,
  nextSectionURL: PropTypes.string,
  isSinglePageForm: PropTypes.bool,
  updateApplicationObject: PropTypes.func,
  containingElementId: PropTypes.string,
  disableEditMode: PropTypes.bool,
  isDataEntryUser: PropTypes.bool,
  disclosureFormTypes: PropTypes.array
}

DisclosureForm.defaultProps = {
  className: "",
  disclosureQuestions: [],
  application: {},
  patchEndpoint: "",
  nextSectionURL: null,
  isSinglePageForm: false,
  updateApplicationObject: () => {},
  containingElementId: null,
  disableEditMode: false,
  isDataEntryUser: false,
  disclosureFormTypes: []
}

export default DisclosureForm
