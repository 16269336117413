import React, { Component } from 'react';
import { Field, connect } from 'react-redux';

export class providerTypeOptions extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    const {
      domain,
      name,
      input,
      data,
      meta: { error, dirty }
    } = this.props

    var providerTypeOptions = domain.providerTypes.map((option, index) => {return <option key={index} value={option.abbrev}>{option.abbrev} - {option.description}</option>})
    return (
      <div>
        <select name={this.props.input.name} value={this.props.input.value} onChange={(event) => {input.onChange(event.target.value)}}>
          <option value=" "></option>
          {providerTypeOptions}
        </select>
        <br/>
        {dirty && error && <span className="error">{error}</span>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    domain: state.domain,
    data: state.provider.data
  }
}

export default connect(mapStateToProps)(providerTypeOptions)
