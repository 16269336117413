import React from 'react'
import { get, omit, isPlainObject } from 'lodash'

/**
* This component displays the properties of a POJO (plain old Javascript object). You can
* rename all or some of the keys and transform all or some of the values.
*
* As an example, we'll use this input object:
*
* const object = {
*  name: 'Marcus',
*  age: 52,
*  married: true,
*  stats: {
*    height: '5\'6\'\'',
*    weight: 135
*  },
*  pets: ['Oscar', 'Percy', 'Bee']
* }
*
* Used like this ...
*
* <ObjectViewer object={object} />
*
* ... with no configuration, it will print out a list of all properties like this:
*
* name: Marcus
* age: 52
* married: true
* stats: {"height":"5'6''","weight":135}
* pets: Oscar, Percy, Bee
*
* If you want to omit some of the keys, you can do so as follows:
*
* <ObjectViewerWithTheme object={object} config={{
*      omit: ['married', 'stats.height']
*    }} />
*
* Which will display this:
*
* name: Marcus
* age: 52
* stats: {"weight":135}
* pets: Oscar, Percy, Bee
*
* While this component doesn't support nested objects (nested ones are just displayed as JSON strings),
* as with 'stats', above, you can use omit keys for child objects and then re-use the component, giving
* it the child as input...
*
* <ObjectViewerWithTheme object={object} config={{
*      omit: [stats']
*    }} />
<ObjectViewerWithTheme object={object.stats} />
*
* name: Marcus
* age: 52
* married: true
* pets: Oscar, Percy, Bee
* height: 5'6''
* weight: 135
*
* You can rename properties:
*
* <ObjectViewerWithTheme object={object} config={{
*     omit: ['married', 'stats.height'],
*     rename: {
*        name: 'First Name',
*        stats: {
*          _parent: 'statistics', // renames 'stats' to 'statistics'
*          weight: 'mass'
*        }
*      }
*    }} />
*
* First Name: Marcus
* age: 52
* statistics: {"mass":135}
* pets: Oscar, Percy, Bee
*
* For complete control, you can use a 'get' list to display the specific properties
* You want displayed. For each item, include the path to the property (e.g. 'name' or
* 'stats.height'), an optional newName (e.g. 'First name' or 'height'), an optional defaultValue,
*  and an optional transformer function:
*
* <ObjectViewerWithTheme object={object} config={{
*      omit: ['stats', 'age'],
*      rename: {
*        name: 'First Name'
*      },
*      get: [
*        {
*          path: 'stats.height',
*          newName: 'height'
*        },
*        {
*          path: 'age',
*          newName: 'birth year',
*          defaultValue: 1066,
*          // transformer functions are described below:
*          transformer: (key, age) => {value: new Date().getFullYear() - age}
*        },
*        {
*          path: 'favoriteColor',
*          defaultValue: 'blue'
*        },
*        {
*          path: 'kids', // we shouldn't see this, since there's no such property and no defaultValue
*          newName: 'Children'
*        }
*      ]
*    }} />
*
* First Name: Marcus
* married: true
* pets: Oscar, Percy, Bee
* height: 5'6''
* birth year: 1966
* favoriteColor: blue
*
* Notice that this displays the custom 'get' properties _and_ any other properties, unless
* they've been omitted. If you want to _only_ display the custom 'get' properties, use the
* special '*' character in omit, which will cause all properties that you don't specify to be
* omitted:
*
* <ObjectViewerWithTheme object={object} config={{
*      omit: ['*'], // omit all
*      // now build a totally custom list
*      get: [
*        {
*          path: 'name'
*        },
*        {
*          path: 'rank',
*          defaultValue: 'Major General'
*        },
*        {
*          path: 'stats.weight',
*          newName: 'weight'
*        }
*      ]
*    }} />
*
* name: Marcus
* rank: Major General
* weight: 135
*
* Some notes about transformer functions. The will receive three arguments: key, value, and
* the entire object. You must return an object with whatever you want to transform:
*
* {
*   key: SOME_NEW_KEY, // optional
*    value: SOME_NEW_VALUE // optional
* }
*
* You can also return a third property, display <Boolean>. It's assumed to be true by
* default.
*
* If you return {display: false}, there's no
* point in adding key or value, because {display: false} means that the property won't
* display in the component.
*
* Here's an example of when you might want to set it to false:
*
* const obj = {birthday: '2018-11-15', present: 'camera tripod', isSurprise: true}
* config = {omit: ['present'], get: [
*    {
*      path: 'present',
*      transformer: (key, value, object) => {
*        if (object.isSurprise) {
*          return {display: false}
*        }
*        return {key, value}
*      }
*    }
* ]}
*
* Note that transformers can return JSX values. {key, value: <b>value</b>}
*
* You can add extra properties and they can be transformed:
*
* const obj = {name: 'Marcus', pets: ['Percy', 'Oscar', 'Bee']}
* const config = {
*     extras: {
*       age: 52,
*       totalRoommates: 1
*     },
*     omit: ['totalRoommates],
*     get: [
*       {
*         path: 'totalRoommates',
*         transformer: key, value, obj) => {
*             const newName = 'Total Roommates'
*             if (obj.pets) {
*               return { key: newName, value: obj.totalRoommates + obj.pets.length }
*             }
*             return { key: newName, value }
*           }
*         }
*       }
*     ]
* }
*
* By default, ObjectViewer wraps makes all key-value pairs <li> components and wraps
* them in <ul>s. You can override this via the config object:
*
* const config = {wrapper: <div className="some-class" />, element: <p />}
*
* You can specify a custom order for the properties by adding config.order,
* an array of key names sorted into the final order. Make sure you use the
* finalized names (as changed by config.rename or config.get)
*
* const obj = {foo: 'a', bar: 'b', year: 2018', baz: 'c'}
* const config = {
*    rename: {foo: 'FU'}
*    omit: ['bar'],
*    get: [{path: bar, neName: 'banana'}],
*    extras: {qux: 'd'},
*    order: ['baz, 'Godzilla', 'qux', 'banana', 'FU']
* }
*
* The final order will be baz: c, qux: d, banana: b, FU: a, year: 2018. Godzilla won't be included,
* because it's not in obj. Properties like year, which are not listed in config.order, will
* be displayed in an arbitrary order after the rest.
**/

const isPojo = (value) => {
  return isPlainObject(value) && !Array.isArray(value) && value !== null
}

const rename = (object, names) => {
  return Object.keys(object).reduce((newObject, key) => {
    if (!names[key] && !typeof [key] !== 'object') {
      newObject[key] = object[key]
      return newObject
    }
    if (isPojo(object[key])) {
      const newKey = names[key]._parent || key
      newObject[newKey] = rename(object[key], names[key])
      return newObject
    }
    if (object[key] !== null && object[key] !== undefined) {
      newObject[names[key]] = object[key]
    }
    return newObject
  }, {})
}

const format = (value) => {
  if (Array.isArray(value)) {
    return value.join(', ')
  } else if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  return String(value)
}

const propertyReducer = object => (customPropertiesHash, {
  path,
  newName,
  defaultValue,
  transformer
}) => {
  const key = newName || path
  const value = get(object, path, defaultValue)
  if (value) {
    const { key: finalKey, value: finalValue, display = true } = transformer ? transformer(key, value, object) : { key, value }
    if (display) {
      customPropertiesHash[finalKey] = finalValue
    }
  }
  return customPropertiesHash
}

const getOrderedArray = (original, order, key) => {
  const specified = original.filter(x => order.includes(x[key]))
  const nonSpecified = original.filter(x => !order.includes(x[key]))
  const ordered = specified.slice().sort((a, b) => {
    const indexA = order.indexOf(a[key])
    const indexB = order.indexOf(b[key])
    if (indexA === -1) {
      return 1
    }
    return indexA - indexB
  })
  return [...ordered, ...nonSpecified]
}

const getPropertiesMarkup = (object, config) => {
  const element = config.element || <li />
  const initialObject = config.extras ? { ...object, ...config.extras } : object
  const gottenProperties = config.get ? config.get.reduce(propertyReducer(initialObject), {}) : {}
  const slimmedObject = config.omit ? omit(initialObject, config.omit) : initialObject
  const renamedObject = config.rename ? rename(slimmedObject, config.rename) : slimmedObject
  const omitAll = config.omit && config.omit.includes('*')
  const formattedObject = Object.keys(renamedObject).reduce((accumulator, key) => {
    accumulator[key] = format(renamedObject[key])
    return accumulator
  }, {})
  const finalObject = omitAll ? gottenProperties : { ...formattedObject, ...gottenProperties }
  const propertiesArray = Object.keys(finalObject).map(key => ({ key, value: finalObject[key] }))
  const orderedArray = config.order ? getOrderedArray(propertiesArray, config.order, 'key') : propertiesArray
  return orderedArray.map(({ key, value }, index) => {
    return React.cloneElement(element, { ...element.props, ...{ key: index } }, (
      <span>
        <span className={`object-viewer-key ${config.keyClass || ''}`}>{key}: </span>
        <span className={`object-viewer-value ${config.valueClass || ''}`}>{value}</span>
      </span>
    ))
  })
}

const ObjectViewer = ({
  object = {},
  config = {}
}) => {
  const wrapper = config.wrapper || <ul />
  return React.cloneElement(wrapper, null, getPropertiesMarkup(object, config))
}

export default ObjectViewer;
