// NOTE: This is a legacy file. Don't add global event bindings like this.
// TODO: Scope this to the pages or components that need this logic.
$(function() {
  var applicationTypeSelector = $("#application-types-container");
  var shouldToggle = applicationTypeSelector.find("div > input").length > 1
  var toggleCb = function(e){
    var selectedRosterType = e.target.value;
    $("input[name='application_type']").prop('checked', false);
    if (selectedRosterType === "Direct") {
      applicationTypeSelector.css('visibility', 'visible');
    } else {
      applicationTypeSelector.css('visibility', 'hidden');
    }
  }
  //Application type determination
  $("input[name='import_type']").on("change", shouldToggle ? toggleCb : null)

  let shouldRefresh   = $("#uploads-table").length ? $("#uploads-table")[0].dataset.shouldRefresh : false;
  let refreshInterval = 3000;
  let listEl          = $("#add-providers-list");
  if (shouldRefresh === 'true') {
    let refreshIntervalId = setInterval(function(){
      jQuery.get("client/add_providers/refresh_uploads" + location.search, function(res){
        listEl.html(res);
        shouldRefresh   = $("#uploads-table", res)[0].dataset.shouldRefresh
        if (shouldRefresh === 'false') {
          clearInterval(refreshIntervalId);
        }
      });
    }, refreshInterval);
  }
});
