$(function(){
  const cred_event = '#checklist_template_checklist_template_schedule_attributes_credential_event',
   show_hide_calcs = '#checklist_template_checklist_template_schedule_attributes_apply_custom_rule',
          triggers = $('#checklist-templates-triggers').length;

  const $validationErrors = $('.validation'),
          $credEventInput = $('#checklist_template_checklist_template_schedule_attributes_credential_event');

  const disableElements = function () {
    for (var i = 0; i < arguments.length; i++) {
      arguments[i].val(null);
      arguments[i].prop('disabled', true);
    }
  };

  const enableElements = function () {
    for (var i = 0; i < arguments.length; i++) {
      arguments[i].prop('disabled', false);
    }
  };

  const isInitial = function () {
    return $(cred_event)[0].value == 'initial';
  };

  const isRecredential = function () {
    return $(cred_event)[0].value == 'recred';
  };

  const disableScheduleFields = function () {
    const $calculator = $('#checklist_template_checklist_template_schedule_attributes_calculator_name'),
           $frequency = $('#checklist_template_checklist_template_schedule_attributes_frequency'),
            $interval = $('#checklist_template_checklist_template_schedule_attributes_interval');

    if (triggers && isInitial()) {
      disableElements($calculator, $interval, $frequency);
    } else if (triggers && isRecredential()) {
      enableElements($calculator, $interval, $frequency);
    } else {
      disableElements($calculator);
      enableElements($interval, $frequency);
    }
  };
  $(cred_event).on('change', disableScheduleFields);
  disableScheduleFields();

  const showHideCalculatorSelection = function () {
    const $interval_frequency = $('.js-interval'),
           $custom_calculator = $('.js-calculator'),
                  $apply_rule = $(show_hide_calcs),
                    $checkbox = $('.js-checkbox');

    if (triggers && isRecredential()) {
      $checkbox.show();
    } else {
      $apply_rule.prop('checked', false);
      $checkbox.hide();
    }

    if (triggers && $apply_rule.prop('checked')) {
      $interval_frequency.hide();
      $custom_calculator.show();
    } else {
      $interval_frequency.show();
      $custom_calculator.hide();
    }
  };
  $(cred_event).on('change', showHideCalculatorSelection);
  $(show_hide_calcs).on('click', showHideCalculatorSelection);
  showHideCalculatorSelection();

  if ($credEventInput) {
    $credEventInput.on('change', function () {
      $validationErrors.each(function (index, element) {
        $(element).remove();
      });
    });
  };

  const attachChangeEvent = function (selector, callback) {
    $(selector).on('change', callback)
  };

  let triggersPage = $('.span12 h4').text(),
      credValue = $('#checklist_template_checklist_template_schedule_attributes_credential_event').val()

  const alertCredEventChange = function(){
    alert('Warning: Updating Credential Event will remove ALL requests associated with this template');
  };

  if(triggersPage.includes("Triggers") && credValue.length != 0 ){
    const cred_event = '#checklist_template_checklist_template_schedule_attributes_credential_event'
    attachChangeEvent(cred_event, alertCredEventChange)
  };
});
