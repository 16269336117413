$(function () {
  $('#segments-practitioner-type-select').select2({
    placeholder: 'Search for a Provider Type to add'
  });

  $('#segments-provider-state-select').select2({
    placeholder: 'Search for a Provider State to add'
  });

  $('#segments-specialty-select').select2({
    placeholder: 'Search for a Provider Specialty to add'
  });
});

