const React = require('react');
import MultiSelect from '@khanacademy/react-multi-select';

/*
 * This is a generic filter that can be used in conjunction with listTable
 */

const defaultFilterMap = [
  {
    column_name: '',
    label: '',
    placeholder: '',
    pattern: '',
    tooltip: '',
    custom_class: '',
    filter_type: 'text', // text, multi_text, select, multi_select, date_range, number_range
    options: null,
    es_query_type: null, // match, terms, term
    es_search_fields: null, // used to search elasticsearch with
    es_exact_field: null, // used for sorting, filtering, and aggregations in elasticsearch
  },
];

const multiSelectValueRenderer = (selected, placeholder) => {
  if (selected.length < 1) {
    return placeholder;
  }
  return `${selected.length} selected`;
};

const ListFilter = ({
  className = '',
  style = {},
  onSubmit = () => {},
  getFilterQueryValue = () => {},
  updateFilterQuery = () => {},
  clearFilterQuery = () => {},
  filterMap = defaultFilterMap,
}) => {
  const handleFilterChange = (filter, value, subKey) => {
    updateFilterQuery(filter, value, subKey);
  };

  const handleMultiTextFilterChange = (filter, value) => {
    const separatorRegex = new RegExp(filter.separator_pattern);
    const valuesArr = value.split(separatorRegex).filter((v) => v !== '');
    if (value.substr(value.length - 1) === ',') valuesArr.push('');

    updateFilterQuery(filter, valuesArr);
  };

  const clearForm = () => {
    clearFilterQuery();
  };

  const createClassFromLabel = (label) => {
    return label.toLowerCase().split(' ').join('-') + '-row';
  }

  const dateFieldStyles = {
    cursor: 'text',
    width: 175,
  };

  return (
    <form
      id="list-filter-form"
      style={style}
      className={`well flex flex-wrap justify-between items-center`}
      onSubmit={onSubmit}
    >
      {filterMap.map((filter, filterIndex) => {
        switch (filter.filter_type) {
          case 'spacer':
            return (
              <div
                key={filterIndex}
                className={filter.custom_class}
              >

              </div>);
          case 'text':
            return (
              <input
                className={filter.custom_class}
                key={filterIndex}
                type="text"
                placeholder={filter.placeholder}
                pattern={filter.pattern}
                onChange={(e) => handleFilterChange(filter, e.target.value)}
                value={getFilterQueryValue(filter.column_name) || ''}
              />
            );
          case 'select':
            return (
              <div className='search-select' key={filterIndex}>
                <select
                  className={filter.custom_class}
                  key={filterIndex}
                  onChange={(e) => handleFilterChange(filter, e.target.value)}
                  value={getFilterQueryValue(filter.column_name) || ''}
                >
                  <option value="">{filter.placeholder}</option>
                  {filter.options.map((option, optionIndex) => {
                    return (
                      <option key={optionIndex} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          case 'multi_text':
            return (
              <input
                className={filter.custom_class}
                key={filterIndex}
                type="text"
                placeholder={filter.placeholder}
                pattern={filter.pattern}
                title={filter.tooltip}
                onChange={(e) => handleMultiTextFilterChange(filter, e.target.value)}
                value={getFilterQueryValue(filter.column_name) ? getFilterQueryValue(filter.column_name).join() : ''}
              />
            );
          case 'multi_select':
            return (
              <div className={filter.custom_class} key={filterIndex}>
                <MultiSelect
                  options={filter.options}
                  selected={getFilterQueryValue(filter.column_name) || []}
                  onSelectedChanged={(selected) => handleFilterChange(filter, selected)}
                  valueRenderer={(selected, options) => multiSelectValueRenderer(selected, filter.placeholder)}
                  disableSearch={filter.disable_search}
                  hasSelectAll={filter.has_select_all}
                />
              </div>
            );
          case 'date_range':
            return (
              <div key={filterIndex} className={createClassFromLabel(filter.label)}>
                <span className="type-heading-normal">{filter.label}</span>
                <input
                  style={dateFieldStyles}
                  value={getFilterQueryValue(filter.column_name, 'gte') || ''}
                  className={`ml2 mr1 ${filter.custom_class}`}
                  type="date"
                  pattern={filter.pattern}
                  title={filter.tooltip}
                  placeholder={filter.placeholder}
                  onChange={(e) => handleFilterChange(filter, e.target.value, 'gte')}
                />
                -
                <input
                  style={dateFieldStyles}
                  value={getFilterQueryValue(filter.column_name, 'lte') || ''}
                  className={`ml1 ${filter.custom_class}`}
                  type="date"
                  pattern={filter.pattern}
                  title={filter.tooltip}
                  placeholder={filter.placeholder}
                  onChange={(e) => handleFilterChange(filter, e.target.value, 'lte')}
                />
              </div>
            );
        }
      })}
      <div className="row-fluid">
        <div className='flex justify-end mt3'>
          <input type="submit" className="app-btn ml2" value="Submit" />
          <button className="app-btn-secondary pull-right ml2" onClick={clearForm}>
            Clear
          </button>
        </div>
      </div>
    </form>
  );
};

export default ListFilter;
