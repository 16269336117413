import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

class AttestationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attestDate: props.application.attest_date,
      isDateInvalid: false,
    };
    this.getProviderName = this.getProviderName.bind(this);
    this.renderDateInput = this.renderDateInput.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  getProviderName() {
    const firstName = _.get(this.props.application, "profile.first_name");
    const lastName = _.get(this.props.application, "profile.last_name");
    return firstName && lastName ? `${firstName} ${lastName}` : "-";
  }

  renderDateInput() {
    if (!this.props.isDataEntryUser)
      return (
        <div className="w5 mra ml3 bb b--black tc">
          <p className="mb2">
            {this.props.application.attest_date
              ? this.props.application.attest_date
              : "-"}
          </p>
        </div>
      );
    return (
      <input
        className={`mra ml3 brand-input brand-input-fixed-width ${
          this.state.isDateInvalid ? "brand-invalid" : ""
        }`}
        type="date"
        value={this.state.attestDate || ""}
        onChange={(e) => this.setState({ attestDate: e.target.value })}
      />
    );
  }

  onSave() {
    if (this.props.isDataEntryUser && !this.state.attestDate) {
      this.setState({ isDateInvalid: true });
      return;
    }
    const body = this.props.isDataEntryUser
      ? JSON.stringify({ attest_date: this.state.attestDate })
      : null;
    const endpoint = `${window.MAIN}/provider_applications/${this.props.application.id}/sign_and_attest.json`;
    fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    }).then((response) => {
      const isValid = response.ok;
      response.json().then((data) => {
        const verification_failed = _.get(data, "verification_failed") || false;
        if (verification_failed === true) {
          this.setState({ isDateInvalid: true });
        } else if (isValid) {
          this.setState({ isDateInvalid: false });
          if (this.props.isSinglePageForm) {
            this.props.updateApplicationObject(data);
          } else {
            if (this.props.nextSectionURL)
              window.location = this.props.nextSectionURL;
          }
        } else {
          // Error message??
          if (!this.props.isSinglePageForm) window.scrollTo(0, 0);
        }
      });
    });
  }

  render() {
    return (
      <form
        className={this.props.className}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          this.onSave();
        }}
      >
        <h4 className="mt0">Authorization &amp; Attestation</h4>
        <p>
          I understand and agree that, as part of the credentialing application
          process for participation, membership and/or clinical privileges
          (hereinafter, referred to as "Participation") at or with each
          healthcare organization indicated on the homepage of andros listed as
          a "Credentialing Institution" (hereinafter, each healthcare
          organization on the list of "Credentialing Organizations" is
          individually referred to as the "Entity"), and any of the Entity's
          affiliated entities, I am required to provide sufficient and accurate
          information for a proper evaluation of my current licensure, relevant
          training and/or experience, clinical competence, health status,
          character, ethics, and any other criteria used by the Entity for
          determining initial and ongoing eligibility for Participation. Each
          Entity and its representatives, employees, and agent(s) acknowledge
          that the information obtained relating to the application process will
          be held confidential to the extent permitted by law.
        </p>

        <p>
          I acknowledge that each Entity has its own criteria for acceptance,
          and I may be accepted or rejected by each independently. I further
          acknowledge and understand that my cooperation in obtaining
          information and my consent to the release of information do not
          guarantee that any Entity will grant me clinical privileges or
          contract with me as a provider of services. I understand that my
          application for Participation with the Entity is not an application
          for employment with the Entity and that acceptance of my application
          by the Entity will not result in my employment by the Entity.
        </p>
        <h4>
          Authorization of Investigation Concerning Application for
          Participation.
        </h4>
        <p>
          I authorize the following individuals including, without limitation,
          the Entity, its representatives, employees, and/or designated
          agent(s); the Entity's affiliated entities and their representatives,
          employees, and/or designated agents; and the Entity's designated
          professional credentials verification organization (collectively
          referred to as "Agents"), to investigate information, which includes
          both oral and written statements, records, and documents, concerning
          my application for Participation. I agree to allow the Entity and/or
          its Agent(s) to inspect and copy all records and documents relating to
          such an investigation.
        </p>
        <h4>
          Authorization of Third-Party Sources to Release Information Concerning
          Application for Participation.
        </h4>
        <p>
          I authorize any third party, including, but not limited to,
          individuals, agencies, medical groups responsible for credentials
          verification, corporations, companies, employers, former employers,
          hospitals, health plans, health maintenance organizations, managed
          care organizations, law enforcement or licensing agencies, insurance
          companies, educational and other institutions, military services,
          medical credentialing and accreditation agencies, professional medical
          societies, the Federation of State Medical Boards, the National
          Practitioner Data Bank, and the Health Care Integrity and Protection
          Data Bank, to release to the Entity and/or its Agent(s), information,
          including otherwise privileged or confidential information, concerning
          my professional qualifications, credentials, clinical competence,
          quality assurance and utilization data, character, mental condition,
          physical condition, alcohol or chemical dependency diagnosis and
          treatment, ethics, behavior, or any other matter reasonably having a
          bearing on my qualifications for Participation in, or with, the
          Entity. I authorize my current and past professional liability
          carrier(s) to release my history of claims that have been made and/or
          are currently pending against me. I specifically waive written notice
          from any entities and individuals who provide information based upon
          this Authorization, Attestation and Release.
        </p>
        <h4>
          Authorization of Release and Exchange of Disciplinary Information.
        </h4>
        <p>
          I hereby further authorize any third party at which I currently have
          Participation or had Participation and/or each third party's agents to
          release "Disciplinary Information," as defined below, to the Entity
          and/or its Agent(s). I hereby further authorize the Agent(s) to
          release Disciplinary Information about any disciplinary action taken
          against me to its participating Entities at which I have
          Participation, and as may be otherwise required by law. As used
          herein, "Disciplinary Information" means information concerning (i)
          any action taken by such health care organizations, their
          administrators, or their medical or other committees to revoke, deny,
          suspend, restrict, or condition my Participation or impose a
          corrective action plan; (ii) any other disciplinary action involving
          me, including, but not limited to, discipline in the employment
          context; or (iii) my resignation prior to the conclusion of any
          disciplinary pro - ceedings or prior to the commencement of formal
          charges, but after I have knowledge that such formal charges were
          being (or are being) contemplated and/or were (or are) in preparation.
        </p>
        <h4>Release from Liability.</h4>
        <p>
          I release from all liability and hold harmless any Entity, its
          Agent(s), and any other third party for their acts performed in good
          faith and without malice unless such acts are due to the gross
          negligence or willful misconduct of the Entity, its Agent(s), or other
          third party in connection with the gathering, release and exchange of,
          and reliance upon, information used in accordance with this
          Authorization, Attestation and Release. I further agree not to sue any
          Entity, any Agent(s), or any other third party for their acts,
          defamation or any other claims based on statements made in good faith
          and without malice or misconduct of such Entity, Agent(s) or third
          party in connection with the credentialing process. This release shall
          be in addition to, and in no way shall limit, any other applicable
          immunities provided by law for peer review and credentialing
          activities. In this Authorization, Attestation and Release, all
          references to the Entity, its Agent(s), and/or other third party
          include their respective employees, directors, officers, advisors,
          counsel, and agents. The Entity or any of its affiliates or agents
          retains the right to allow access to the application information for
          purposes of a credentialing audit to customers and/or their auditors
          to the extent required in connection with an audit of the
          credentialing processes and provided that the customer and/or their
          auditor executes an appropriate confidentiality agreement. I
          understand and agree that this Authorization, Attestation and Release
          is irrevocable for any period during which I am an applicant for
          Participation at an Entity, a member of an Entity's medical or health
          care staff, or a participating provider of an Entity. I agree to
          execute another form of consent if law or regulation limits the
          application of this irrevocable authorization. I understand that my
          failure to promptly provide another consent may be grounds for
          termination or discipline by the Entity in accordance with the
          applicable bylaws, rules, and regulations, and requirements of the
          Entity, or grounds for my termination of Participation at or with the
          Entity. I agree that information obtained in accordance with the
          provisions of this Authorization, Attestation and Release is not and
          will not be a violation of my privacy.
        </p>

        <p>
          I certify that all information provided by me in my application is
          current, true, correct, accurate and complete to the best of my
          knowledge and belief, and is furnished in good faith. I will notify
          the Entity and/or its Agent(s) within 10 days of any material changes
          to the information (including any changes/challenges to licenses, DEA,
          insurance, malpractice claims, NPDB/HIPDB reports, discipline,
          criminal convictions, etc.) I have provided in my application or
          authorized to be released pursuant to the credentialing process. I
          understand that corrections to the application are permitted at any
          time prior to a determination of Participation by the Entity, and must
          be submitted online or in writing, and must be dated and signed by me
          (may be a written or an electronic signature). I acknowledge that the
          Entity will not process an application until they deem it to be a
          complete application and that I am responsible to provide a complete
          application and to produce adequate and timely information for
          resolving questions that arise in the application process. I
          understand and agree that any material misstatement or omission in the
          application may constitute grounds for withdrawal of the application
          from consideration; denial or revocation of Participation; and/or
          immediate suspension or termination of Participation. This action may
          be disclosed to the Entity and/or its Agent(s). I further acknowledge
          that I have read and understand the foregoing Authorization,
          Attestation and Release and that I have access to the bylaws of
          applicable medical staff organizations and agree to abide by these
          bylaws, rules and regulations. I understand and agree that a facsimile
          or photocopy of this Authorization, Attestation and Release shall be
          as effective as the original.
        </p>
        <div className="flex items-center mt4">
          <div className="w5 tc signature">
            <p className="mb2">{this.getProviderName()}</p>
          </div>
          {this.renderDateInput()}
          {!this.props.disableEditMode ? (
            <input
              type="submit"
              value={
                this.props.isDataEntryUser
                  ? "Submit Attest Date"
                  : "Sign and Attest Now"
              }
              className="app-btn pv2 ph3"
            />
          ) : null}
          {this.props.disableEditMode && !this.props.isSinglePageForm ? (
              <a className="app-btn-secondary ml2 pv2 ph3" type="button" href={this.props.nextSectionURL}>Next</a>
          ) : null}
        </div>
        {this.props.isDataEntryUser && this.state.isDateInvalid ? (
          <p className="brand-red">
            Attest date must be within the past {this.props.attestDateThreshold}{" "}
            days.
          </p>
        ) : null}
      </form>
    );
  }
}

AttestationForm.propTypes = {
  className: PropTypes.string,
  application: PropTypes.object,
  nextSectionURL: PropTypes.string,
  isSinglePageForm: PropTypes.bool,
  updateApplicationObject: PropTypes.func,
  disableEditMode: PropTypes.bool,
  isDataEntryUser: PropTypes.bool,
  attestDateThreshold: PropTypes.number,
};

AttestationForm.defaultProps = {
  className: "",
  application: {},
  nextSectionURL: "",
  isSinglePageForm: false,
  updateApplicationObject: () => {},
  disableEditMode: false,
  isDataEntryUser: false,
  attestDateThreshold: null,
};

export default AttestationForm;
