/* eslint-disable camelcase */
/* eslint-disable indent */

import React from 'react';

// adapted from https://codereview.stackexchange.com/questions/183417/pagination-algorithm-in-js
const getPagesInRange = (currentPage, total = 20, length = 5) => {
  const min = 1
  if (length > total) length = total;

  let start = currentPage - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + total - length);

  return Array.from({ length: length }, (el, i) => start + i);
}

const getPagesMarkup = (range, currentPage, onPageSelect) =>
  range.map((pageNumber, index) => {
    if (pageNumber === currentPage) {
      return <span className="page current" key={index}>{pageNumber}</span>
    } else {
      return (
        <span
          className="page pointer"
          style={{ userSelect: 'none' }}
          key={index}
          onClick={() => {
            onPageSelect(pageNumber)
          }}>
          <a>{pageNumber}</a>
        </span>
      )
    }
  })

const PaginationNav = ({
  className = '',
  itemsPerPage = 10,
  onPageSelect = () => {},
  currentPage = 1,
  totalItems = 10
}) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage)
  if (currentPage > pageCount) {
    throw new Error(`currentPage is greater than the total number of pages: ${pageCount}`)
  }

  const pagesToDisplay = getPagesInRange(currentPage, pageCount, 5)

  const firstPageToDisplay = pagesToDisplay[0]

  const lastPageToDisplay = pagesToDisplay[pagesToDisplay.length - 1]

  const showFirstPageSet = () => {
    onPageSelect(1)
  }

  const showLastPageSet = () => {
    onPageSelect(pageCount)
  }

  const showPreviousPageSet = () => {
    const previousPage = currentPage - 1
    if (currentPage > 1) {
      onPageSelect(previousPage)
    }
  }

  const showNextPageSet = () => {
    const nextPage = currentPage + 1
    if (currentPage < pageCount) {
      onPageSelect(nextPage)
    }
  }

  return (
    <nav className={`${className} pagination`}>
      {currentPage > 1 ? <span className="first pointer" onClick={showFirstPageSet}><a>&#171; First</a></span> : ''}
      {currentPage > 1 ? <span className="prev pointer" onClick={showPreviousPageSet}><a>&#8249; Prev</a></span> : ''}
      {firstPageToDisplay > 1 ? <span className="page gap">...</span> : ''}
      {getPagesMarkup(pagesToDisplay, currentPage, onPageSelect)}
      {lastPageToDisplay < pageCount ? <span className="page gap">...</span> : ''}
      {currentPage < pageCount ? <span className="next pointer" onClick={showNextPageSet}><a>Next &#8250;</a></span> : ''}
      {currentPage < pageCount ? <span className="last pointer" onClick={showLastPageSet}><a>Last &#187;</a></span> : ''}
    </nav>
  );
};

export default PaginationNav
