const React = require('react');
import moment from 'moment';
import { isNull } from 'lodash';
import IconFlagSVG from '../../../../../assets/svg/icon-issue-flagged.svg'
// import IconFlagSVG from '../../../javascripts/components/svgs/iconFlag'
/*
 * This is a generic table that can be used to display any data
 * It accepts a tableData object that has the following structure:
 * {
 *    columnData: [
 *      {
 *        header_type: string, -- right now either 'text' or 'download'
 *        header_text: string, -- text that should display in the header column
 *        row_key: string, -- should be the key for each column in the row data
 *        column_type: string -- indicates the type of value the column represents
 *        sortable: boolean, -- determines if the user can click to sort by this column
 *        boolean_icon_class:  string -- class of the icon to display when column is of type 'boolean-icon'
 *        custom_class: string, -- custom class added to each element of this column
 *        custom_text: string, -- text to display rather than the value of this column
 *        custom_map: obj, -- object used to map a value to another
 *        es_sort_field: string -- name of elasticsearch field to sort on
 *      }
 *    ],
 *    rowData: [
 *      {
 *        key: value -- these will be created by whatever data source this table is iterating over
 *      }
 *    ]
 * }
 *
 *
 */

const defaultTableData = {
  columnData: [
    {
      header_type: 'text',
      header_text: '',
      row_key: '',
      column_type: 'text',
      sortable: false,
      es_sort_field: '',
    },
  ],
  rowData: [],
};

const onSort = (column, direction, es_sort_field) => {
  const isCurrentKey = column === es_sort_field;
  return isCurrentKey && direction === 'asc'
    ? { column: es_sort_field, direction: 'desc' }
    : isCurrentKey && direction === 'desc'
    ? { column: null, direction: null }
    : { column: es_sort_field, direction: 'asc' };
};

const getCaret = (direction) => (direction === 'asc' ? <span>&#9650;</span> : <span>&#9660;</span>);

const getSortLink = (header_text, es_sort_field, { column, direction }, updateSortData) => (
  <a className="pointer" onClick={() => updateSortData(onSort(column, direction, es_sort_field))}>
    {header_text}{column === es_sort_field ? getCaret(direction) : ''}
  </a>
);

const getHeaderElement = (
  { header_type, header_text, sortable, es_sort_field },
  sortData,
  updateSortData,
  index,
  onDownload
) => {
  switch (header_type) {
    case 'download':
      return (
        <th key={index} className="table-header-download-button">
          <button onClick={onDownload} className="btn app-btn-mini">
            {header_text}
          </button>
        </th>
      );
    case 'none':
      return <th key={index}></th>;
    default:
      return (
        <th key={index}>
          {sortable ? getSortLink(header_text, es_sort_field, sortData, updateSortData) : header_text}
        </th>
      );
  }
};

const getFormattedDate = (date) => (date && date.length > 1 ? moment(date).format('MM/DD/YYYY') : '');

const getRowElements = ({ rowData, columnData }, buildLink, linkClickTracker, dateFormatFn) => {
  return rowData.map((row, rowIndex) => {
    return (
      <tr className="ttc" key={rowIndex}>
        {columnData.map((column, columnIndex) => {
          switch (column.column_type) {
            case 'link':
              return (
                <td key={columnIndex} className={column.custom_class} style={{ textAlign: 'center' }}>
                  {buildLink(row, column, linkClickTracker)}
                </td>
              );
            case 'date':
              return (
                <td key={columnIndex} className={column.custom_class}>
                  {dateFormatFn(row[column.row_key])}
                </td>
              );
            case 'boolean-icon':
              const isTrue = row[column.row_key] === true || row[column.row_key] === 'true' ? true : false;
              return (
                <td key={columnIndex} className={`v-mid ${column.custom_class || ''}`}>
                    {isTrue ? <span className="icon icon-large icon-issue-flagged icon-warning"><IconFlagSVG /></span> : ''}
                </td>
              );
            case 'number':
              return (
                <td key={columnIndex} className={column.custom_class}>
                  {isNull(row[column.row_key]) ? '' : row[column.row_key]}
                </td>
              );
            default:
              return (
                <td key={columnIndex} className={column.custom_class}>
                  {column.custom_map ? column.custom_map[row[column.row_key]] : row[column.row_key]}
                </td>
              );
          }
        })}
      </tr>
    );
  });
};

const ListTable = ({
  className = '',
  style = {},
  tableData = defaultTableData,
  buildLink = () => '',
  sortData = {},
  updateSortData = () => {},
  onDownload = () => {},
  linkClickTracker = () => {},
  dateFormatFn = getFormattedDate,
}) => {
  tableData = tableData || defaultTableData;

  if (!tableData.rowData.length) {
    return (
      <div>
        <p className="mv3">No results found</p>
      </div>
    );
  }

  return (
    <table id="roster" className={`table table-striped sortable-table ${className}`} style={style}>
      <thead>
        <tr>
          {tableData.columnData.map((column, index) =>
            getHeaderElement(column, sortData, updateSortData, index, onDownload)
          )}
        </tr>
      </thead>
      <tbody>{getRowElements(tableData, buildLink, linkClickTracker, dateFormatFn)}</tbody>
    </table>
  );
};

export default ListTable;
