import React, { Component } from 'react';

// for flash types, see apps/assets/stylesheets/bootstraps_and_overrides.css.scss

export default class FlashMessage extends Component {
  componentDidMount() {
    // Use setTimeout function to remove flash message after 6 seconds
    if (!this.props.noTimeout) {
      setTimeout(this.props.hide, 5500)
    }
  }
  render() {
    const { type, message, hide, noTimeout } = this.props
    return(
      <div className={`alert alert-${type} mt3`}>
        <a className="close" onClick={hide}>×</a>
        <div id="flash_alert">
          {message}
        </div>
      </div>
    )
  }
}
