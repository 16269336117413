import React from "react"
import * as _ from "lodash"
import MultiSelect from '@khanacademy/react-multi-select'


class OutreachForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.existingReasonIds ? this.props.existingReasonIds : [],
      outreachDescription: this.props.existingDescription ? this.props.existingDescription : "",
      details: "",
      error_message: ""
    }
    this.submitForOutreach = this.submitForOutreach.bind(this)
  }

  submitForOutreach() {
    let payload = JSON.stringify({
      ...this.state
    })
    let endpoint = `${window.origin}/provider_applications/${this.props.applicationId}/submit_for_outreach`
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.token,
      },
      body: payload
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        details: data.details,
        error_message: data.error_message
      })
    })
  }

  render () {
    return (
      <div className="pb3">
        <h4 className="mt0 brand-dark-blue">Outreach Reasons</h4>
        <div style={{color: this.state.error_message.length == 0 ? "green" : "red"}}>
          {this.state.error_message.length > 0 ? this.state.error_message : this.state.details}
        </div>
        <div className="brand-multi-select">
          <MultiSelect
            options={this.props.outreachReasons.map(or => ({label: or.name, value: or.id}))}
            overrideStrings={{
              selectSomeItems:"What are the reasons?"
            }}
            selected={this.state.selected}
            onSelectedChanged={selected => this.setState({selected})}
          />

        </div>
        <div className="pt2">
          <textarea style={{minHeight: '200px'}}
                    className="w-100"
                    placeholder="Additional Notes"
                    value={this.state.outreachDescription}
                    onChange={e => {this.setState({outreachDescription: e.target.value})}}
                    ></textarea>
        </div>
        <div className="flex justify-end">
          <button onClick={this.submitForOutreach}
                className="brand-save-btn pa2 mt3">
            Submit for outreach
          </button>
        </div>
      </div>
    )
  }
}


export default OutreachForm
