const leftNavModule = function() {
  const nav = document.getElementsByClassName('andros-nav')[0],
    navTextElements = document.querySelectorAll('.andros-nav h6'),
    handleClosure = event => !nav.contains(event.target) && toggleNav();

  const removeWindowListener = function(){
    window.removeEventListener('click', handleClosure);
  }
  const addWindowListener = function(){
    window.addEventListener('click', handleClosure);
  }

  const toggleNavDisplayText = () => {
    navTextElements.forEach((element, index) => {
      element.classList.toggle('hide')
      element.classList.toggle('visible')
    });
  }

  const toggleToolTip = (activateTooltip) => {
    const navLinks = document.getElementsByClassName('andros-nav_link');
    navLinks.forEach(element => {
      if(activateTooltip){
        //boostrap tooltip function ref: https://getbootstrap.com/2.3.2/javascript.html#tooltips
        $(element).tooltip()
      } else {
        $(element).tooltip('destroy')
      }
    });
  }
  const manageAndrosImageDisplay = () => {
    document.getElementsByClassName('logo-rebrand')[0].classList.toggle('hide');
    document.getElementsByClassName('logo-rebrand--white')[0].classList.toggle('hide');
    document.getElementsByClassName('logo-rebrand--coral')[0].classList.toggle('hide');
  }

  const toggleNav = () => {
    const navWasOpen = nav.classList.contains('active');
    if(navWasOpen){
      //close it
      removeWindowListener()
      window.sessionStorage.setItem('was-i-closed?', 'true');
    } else {
      //open it
      window.sessionStorage.setItem('was-i-closed?', 'false');
      addWindowListener();
    }
    nav.classList.toggle('inactive');
    nav.classList.toggle('active');
    toggleToolTip(navWasOpen);
    manageAndrosImageDisplay();
    toggleNavDisplayText();
  }

  const setInitialNavState = () => {
    const wasNavClosed = window.sessionStorage.getItem('was-i-closed?');
    if(wasNavClosed === 'true' || (wasNavClosed === null)){
      toggleToolTip(true)
    }
    if(wasNavClosed === 'false'){
      toggleNav()
    }
  }

  const navClickListener = () => {
    nav.addEventListener('click', event => {
      const navLinkContainer = event.target.closest('.andros-nav_link')
      if ((navLinkContainer && navLinkContainer.contains(event.target))||
        event.target.classList.contains('logo-rebrand') || event.target.nodeName === 'H6' ||
        event.target.classList.contains('andros-nav_link')) {
        return false
      }

      toggleNav();
    });
  }

  return {
    publicMethod: function(nav) {
      setInitialNavState();
      navClickListener();
    }
  }
}
$(function(){
  const navEl = document.getElementsByClassName('andros-nav')[0];
  if(navEl) {
    const navModule = leftNavModule()
    navModule.publicMethod(navEl)
  }
});

