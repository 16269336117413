$(function () {
  $(document).on("keyup", "#locations_audit_comment", function(e) {
    let $this = $(this);
    let value = $this.val().trim();
    let $parent = $this.closest('.form-inputs');

    // If an audit comment is required, conditionally enable/disable
    // the delete button and change its style to reflect the state
    if ($this.prop('required')) {
      let button = $parent.find("button[type='submit']")

      if (value === '') {
        button.attr('disabled', 'disabled');
        button.addClass('btn-disabled');
      } else {
        button.removeAttr('disabled');
        button.removeClass('btn-disabled');
      }
    }
  });
});
