// NOTE: This is a legacy file. Don't add global event bindings and functions like this.
$(function () {
  $('#physical_provider_dob, #physical_provider_checklists_attributes_0_cred_due_date').datepicker();
  addLicense();
});

window.validateNPI = npi => {
  var luhn = '80840' + npi.toString(); // for NPI must prepend 6 digit string to check against luhn
  var len = luhn.length,
    mul = 0,
    prodArr = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
    ],
    sum = 0;

  while (len--) {
    sum += prodArr[mul][parseInt(luhn.charAt(len), 10)];
    mul ^= 1;
  }

  return (sum % 10 === 0 && sum > 0 && npi.length == 10)
}

window.addLicense = () => {
  $('.js-add-license').on('click', function (e) {
    e.preventDefault()

    newLicenseElement = $('.js-licenses-row').clone().removeClass('js-licenses-row');
    newcdsElement = $('.js-cdshash-row').clone().removeClass('js-cdshash-row');

    newLicenseElement.find('select, input').val('');
    newcdsElement.find('select, input').val('');

    $('.js-licenses-wrapper').append(newLicenseElement);
    $('.js-cdshash-wrapper').append(newcdsElement);
  });
}
