import React, { Component } from 'react'

function IconFlag(){
  const cls1Style = {
    fill:'none',
    stroke:'currentColor',
    strokeLinecap:'round',
    strokeLineJoin:'round',
    strokeWidth:'5px'
  }
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 227 227">
      <defs>
      </defs>
      <path className="cls-1"
            d="M198.56,126.06c-16.13-31.08-6.25-64.43,0-79.7a5.78,5.78,0,0,0,.43-2.6,5.72,5.72,0,0,0-6.1-5.3c-16.44,1.13-49.48,3.5-62.73,5.4-10.55,1.5-9-12.07-22.61-13.57C89.49,28.25,61,35,47.42,38.66V30c0-.15,0-.3,0-.45a14,14,0,1,0-14.76.37V217.43a7.4,7.4,0,1,0,14.79,0V133.52c47.21-21.9,64.05-10.34,76.7.71,12.06,10.56,50.73,1.45,70.43-.63a5.11,5.11,0,0,0,1.79-.55A5.17,5.17,0,0,0,198.56,126.06ZM47.42,119.22V52.9C59.52,77.12,53.67,102.66,47.42,119.22Z"/>
    </svg>
  )
}
export default IconFlag;
