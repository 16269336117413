$(function () {
  const $segmentsProviderSpecialtiesInput = $('#checklist_template_specialty_segment_attributes_operator'),
             $segmentsProviderStatesInput = $('#checklist_template_license_state_segment_attributes_operator'),
              $segmentsProviderTypesInput = $('#checklist_template_practitioner_type_segment_attributes_operator'),
                        $validationErrors = $('.validation');

  if ($segmentsProviderTypesInput) {
    $segmentsProviderTypesInput.on('change', function () {
      $validationErrors.each(function (index, element) {
        $(element).remove();
      });
    });
  };

  if ($segmentsProviderStatesInput) {
    $segmentsProviderStatesInput.on('change', function () {
      $validationErrors.each(function (index, element) {
        $(element).remove();
      });
    });
  };

  if ($segmentsProviderSpecialtiesInput) {
    $segmentsProviderSpecialtiesInput.on('change', function () {
      $validationErrors.each(function (index, element) {
        $(element).remove();
      });
    });
  };
});
