import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import json2csv from 'json2csv';
import { connect } from 'react-redux';

class FileExport extends Component {
  constructor(props) {
    super(props)
  }
  generate() {
    // if (fullAccess(this.props.user, 'facility_documents')) {
      json2csv({ data: this.props.data, fields: this.props.header }, function(err, csv) {
        if (err) console.log(err);
        var data = new Blob([csv], {type: 'text/csv'});
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement('a');

        tempLink.href = csvURL;
        tempLink.setAttribute('target', '_blank');
        tempLink.setAttribute('download', this.props.filename);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }.bind(this));
    // } else {
      // this.invalidPermission()
    // }
  }
  render() {
    return (
      <div>
        <div onClick={this.generate.bind(this)}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

FileExport.PropTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  filename: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(FileExport)
