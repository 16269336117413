// NOTE: This is a legacy file. Don't add global event bindings like this.
$(function() {
	$("#q_reset").click(function() {
		$(".search-field").val('');
	});

	$('a.removeField').click(function(){
    $(this).parent().remove();
  });
});
