import React from "react"
import PropTypes from "prop-types"
import * as _ from "lodash"
import ViewEditForm from "./ViewEditForm"
import IconFlagSVG from '../../../../../assets/svg/icon-issue-flagged.svg'
// import IconFlagSVG from '../../../javascripts/components/svgs/iconFlag'
import IconXSVG from '../../../../../assets/svg/icon-deny-cancel-neg.svg'

class FormArray extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formList: this.getFormList(props.application),
      isAddingNewItem: false,
      sectionNotApplicable: this.getSectionNotApplicable(),
      verificationErrors: this.getVerificationErrors(props.application),
      showVerificationDisclaimer: this.getShowVerificationDisclaimer(props.application)
    }
    this.getFormList = this.getFormList.bind(this)
    this.isMissingDependentProperty = this.isMissingDependentProperty.bind(this)
    this.createNewRow = this.createNewRow.bind(this)
    this.updateFormList = this.updateFormList.bind(this)
    this.removeNewListItem = this.removeNewListItem.bind(this)
    this.finishAddingNewItem = this.finishAddingNewItem.bind(this)
    this.removeItemFromList = this.removeItemFromList.bind(this)
    this.getSectionNotApplicable = this.getSectionNotApplicable.bind(this)
    this.toggleNotApplicable = this.toggleNotApplicable.bind(this)
    this.getVerificationErrors = this.getVerificationErrors.bind(this)
    this.getShowVerificationDisclaimer = this.getShowVerificationDisclaimer.bind(this)
  }

  getFormList(appObj) {
    if (this.props.subsectionName) {
      return _.get(appObj, `${this.props.sectionName}.${this.props.subsectionName}.${this.props.listName}`)
        ? _.get(appObj, `${this.props.sectionName}.${this.props.subsectionName}.${this.props.listName}`).filter(form => form['percent_complete'] > 0)
        : []
    } else {
      return _.get(appObj, `${this.props.sectionName}.${this.props.listName}`)
        ? _.get(appObj, `${this.props.sectionName}.${this.props.listName}`).filter(form => form['percent_complete'] > 0)
        : []
    }
  }

  getVerificationErrors(appObj) {
    if (_.get(appObj, this.props.verificationObjPath)
      && _.get(appObj, `${this.props.verificationObjPath}.is_verified`) === false
      && _.get(appObj, `${this.props.verificationObjPath}.outreach_reasons`)) {
      return _.get(appObj, `${this.props.verificationObjPath}.outreach_reasons`).map(outreach_reason => outreach_reason.summary ? outreach_reason.summary : outreach_reason.details)
    }
    return []
  }

  isMissingDependentProperty() {
    if (this.props.dependentOnPropertyPath) {
      let dependentProperty = _.get(this.props.application, this.props.dependentOnPropertyPath)
      return dependentProperty ? false : true
    }
    return false
  }

  createNewRow() {
    if (!this.state.isAddingNewItem && !this.state.sectionNotApplicable) {
      let newRow = {}
      this.props.formData.forEach(column => {
        column.fields.forEach(field => {
          newRow[field.attribute] = null
        })
      })
      this.setState({formList: [...this.state.formList, newRow], isAddingNewItem: true})
    }
  }

  removeNewListItem() {
    if (this.state.isAddingNewItem) {
      let newFormList = this.state.formList
      newFormList.pop()
      this.setState({formList: newFormList, isAddingNewItem: false})
    }
  }

  updateFormList(newFormList) {
    this.setState({formList: newFormList.filter(form => form['percent_complete'] > 0)})
  }

  finishAddingNewItem(data) {
    this.setState({
      isAddingNewItem: false,
      formList: this.getFormList(data),
      verificationErrors: this.getVerificationErrors(data),
      showVerificationDisclaimer: this.getShowVerificationDisclaimer(data)
    })
    if (this.props.isSinglePageForm) this.props.updateApplicationObject(data)
  }

  removeItemFromList(data) {
    this.setState({
      verificationErrors: this.getVerificationErrors(data),
      showVerificationDisclaimer: this.getShowVerificationDisclaimer(data)
    })
    if (this.props.isSinglePageForm) this.props.updateApplicationObject(data)
  }

  getSectionNotApplicable() {
    // Negate answer because UI displays negative and we store a positive
    if (this.props.subsectionName) {
      if (this.props.hasNotApplicableToggle
        && _.get(this.props.application, `${this.props.sectionName}.${this.props.subsectionName}`)
        && _.get(this.props.application, `${this.props.sectionName}.${this.props.subsectionName}`).hasOwnProperty(this.props.notApplicableOptions.key)) {
        if (_.get(this.props.application, `${this.props.sectionName}.${this.props.subsectionName}.${this.props.notApplicableOptions.key}`) === "false") {
          return true
        } else if (_.get(this.props.application, `${this.props.sectionName}.${this.props.subsectionName}.${this.props.notApplicableOptions.key}`) === "true") {
          return false
        }
      }
    } else {
      if (this.props.hasNotApplicableToggle
        && _.get(this.props.application, `${this.props.sectionName}`)
        && _.get(this.props.application, `${this.props.sectionName}`).hasOwnProperty(this.props.notApplicableOptions.key)) {
        if (_.get(this.props.application, `${this.props.sectionName}.${this.props.notApplicableOptions.key}`) === "false") {
          return true
        } else if (_.get(this.props.application, `${this.props.sectionName}.${this.props.notApplicableOptions.key}`) === "true") {
          return false
        }
      }
    }
    return false
  }

  toggleNotApplicable(checked) {
    if (!this.state.formList.length) {
      this.setState({sectionNotApplicable: checked})
      const hasSection = !checked
      const appObj = {provider_id: this.props.application.provider_id}
      appObj[this.props.sectionName] = {}
      if (this.props.subsectionName) {
        appObj[this.props.sectionName][this.props.subsectionName] = {}
        appObj[this.props.sectionName][this.props.subsectionName][this.props.notApplicableOptions.key] = hasSection.toString()
        appObj[this.props.sectionName][this.props.subsectionName][this.props.listName] = (checked || this.props.allowBlankArrayWhenEmpty) ? [] : [{}]
      } else {
        appObj[this.props.sectionName][this.props.notApplicableOptions.key] = hasSection.toString()
        appObj[this.props.sectionName][this.props.listName] = (checked || this.props.allowBlankArrayWhenEmpty) ? [] : [{}]
      }
      fetch(`${window.MAIN}${this.props.patchEndpoint}.json`, {
        method: 'PATCH',
        body: JSON.stringify({application: appObj}),
        headers: {'Content-Type': 'application/json'}
      }).then(response => {
        const isValid = response.ok
        response.json().then(data => {
          if (isValid) {
            if (this.props.isSinglePageForm) this.props.updateApplicationObject(data)
          }
        })
      })
    }
  }

  getShowVerificationDisclaimer(appObj) {
    return (this.props.hasVerificationDisclaimer
      && !_.get(appObj, this.props.verificationDisclaimerOptions['verificationPath']))
  }

  render() {
    const isInputDeactivated = () => {
      if (this.state.sectionNotApplicable) return true
      if (this.isMissingDependentProperty()) return true
      if (this.props.disableEditMode) return true
      return false
    }

    return (
      <div className={this.props.className}>
        <div className="flex items-center relative">
          <h4 className="mv0">{this.props.title}</h4>
          <span
            className={`pointer flex icon icon-deny-cancel icon-neg f3 ml3 rotate-45 hover-opacity ${isInputDeactivated() ? 'deactivated-input' : ''} ${this.props.hideIcons ? 'dn' : ''}`}
            onClick={this.createNewRow}>
            <IconXSVG />
          </span>
          {
            this.props.hasNotApplicableToggle
            ? <div className={`ml4 flex justify-between items-center ${(this.state.formList.length || this.props.disableEditMode) ? 'deactivated-input' : ''}`}>
                <input type="checkbox" checked={this.state.sectionNotApplicable} onChange={(e) => this.toggleNotApplicable(e.target.checked)} />
                <span className="ml2">{this.props.notApplicableOptions.text}</span>
              </div>
            : null
          }
          {
            this.state.showVerificationDisclaimer
            ? <p className="black mb0 ml4 flex items-center">
                <span className="flex icon icon-flag icon-warning mr1"><IconFlagSVG /></span>
                {this.props.verificationDisclaimerOptions['message']}
              </p>
            : null
          }
        </div>
        {
          this.isMissingDependentProperty()
          ? <p className="mt2 brand-red">{this.props.missingDependentPropertyErrorMessage}</p>
          : null
        }
        {
          this.state.verificationErrors.map((verificationError, index) => <p className="mt2 brand-red" key={index}>{verificationError}</p>)
        }
        {
          this.state.formList.map((listItem, index) => (
            <ViewEditForm
              className="mv3"
              formData={this.props.formData}
              application={this.props.application}
              sectionName={this.props.sectionName}
              subsectionName={this.props.subsectionName}
              listName={this.props.listName}
              formList={this.state.formList}
              updateFormList={this.updateFormList}
              isNewListItem={this.state.isAddingNewItem && index === this.state.formList.length - 1}
              finishAddingNewItem={this.finishAddingNewItem}
              removeItemFromList={this.removeItemFromList}
              removeNewListItem={this.removeNewListItem}
              nextSectionURL={this.props.nextSectionURL}
              patchEndpoint={this.props.patchEndpoint}
              format="list"
              key={index}
              index={index}
              isSinglePageForm={this.props.isSinglePageForm}
              updateApplicationObject={this.props.updateApplicationObject}
              disableEditMode={this.props.disableEditMode}
              allowBlankArrayWhenEmpty={this.props.allowBlankArrayWhenEmpty}
            />
          ))
        }
      </div>
    )
  }
}

FormArray.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  formData: PropTypes.array,
  application: PropTypes.object,
  sectionName: PropTypes.string,
  subsectionName: PropTypes.string,
  listName: PropTypes.string,
  nextSectionURL: PropTypes.string,
  patchEndpoint: PropTypes.string,
  hasNotApplicableToggle: PropTypes.bool,
  notApplicableOptions: PropTypes.object,
  dependentOnPropertyPath: PropTypes.string,
  missingDependentPropertyErrorMessage: PropTypes.string,
  isSinglePageForm: PropTypes.bool,
  updateApplicationObject: PropTypes.func,
  hasVerificationDisclaimer: PropTypes.bool,
  verificationDisclaimerOptions: PropTypes.object,
  verificationObjPath: PropTypes.string,
  disableEditMode: PropTypes.bool,
  hideIcons: PropTypes.bool,
  allowBlankArrayWhenEmpty: PropTypes.bool
}

FormArray.defaultProps = {
  className: "",
  title: "",
  formData: [],
  application: {},
  sectionName: "",
  subsectionName: "",
  listName: "",
  nextSectionURL: null,
  patchEndpoint: null,
  hasNotApplicableToggle: false,
  notApplicableOptions: null,
  dependentOnPropertyPath: null,
  missingDependentPropertyErrorMessage: null,
  isSinglePageForm: false,
  updateApplicationObject: () => {},
  hasVerificationDisclaimer: false,
  verificationDisclaimerOptions: null,
  verificationObjPath: null,
  disableEditMode: false,
  hideIcons: false,
  allowBlankArrayWhenEmpty: false
}

export default FormArray
