import React from 'react';
import ReactDOM from 'react-dom';

class Uploads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploads: props.uploads
    };
    this.addUpload = this.addUpload.bind(this);
  }

  addUpload(upload) {
    var updatedUploads = this.state.uploads.slice()
    updatedUploads.push(upload)
    this.setState({uploads: updatedUploads})
  }

  render() {
    var uploads = this.props.uploads
    return (
      <div>
      <NewUpload onNewUpload={this.addUpload}/>
      </div>
    )
  }
}

class NewUpload extends React.Component {
  handleSubmit(e) {
    e.preventDefault()

    var fileInput = document.getElementById('attachment')
    var file = fileInput.files[0]
    var formData = new FormData()

    var attachment = this.refs.attachment.getDOMNode().value.trim()
    this.refs.attachment.getDOMNode().value = ""
    formData.append("attachment", file)

    $.ajax({
      url: "/providers/"+ window.provider_id + "/elements.json",
      dataType: "json",
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
        this.props.onNewUpload(data)
      }.bind(this),
      error: function(xhr, status, err) {
        console.log("error creating post", xhr, status, err)
      }.bind(this)
    })
  }

  render() {
    return (
      <div>
        <label className="file_upload_label control-label">File</label>
        <div className="controls">
          <input type="file" ref="attachment" id="attachment" name="requesty_physical_element[verification]" className="file_upload" />
        </div>
      </div>
    )
  }
}

window.backgroundUploader = function(id, uploads) {
  ReactDOM.render(
    <Uploads uploads={uploads} />,
    document.getElementById(id)
  )
}
