/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Import packages
import 'core-js/stable'; // Include this polyfill globally to support js code inside of rails views, on older browsers
import 'regenerator-runtime/runtime.js'; // This is part of the js polyfill to support async functions globally
import 'jquery';
import List from 'list.js';
import 'multiple-select';
import { debounce } from 'lodash';

// Import vendor js files
import 'javascripts/bootstrap';
import 'javascripts/bootstrap-datepicker';
import 'javascripts/crossfilter.min';
import 'javascripts/jquery.placeholder';
import 'javascripts/jquery-ujs';

// Import globally used components
// NOTE: These are legacy components and view logic.
// Do not use this pattern of adding components globally. Import components only where you need them.
import 'src/javascripts/components/add_providers/add_providers';
import 'src/javascripts/components/background_uploader/upload.js';
import 'src/javascripts/components/clients/clients';
import 'src/javascripts/components/elements/element_destroy_audit_comment';
import 'src/javascripts/components/home_address/home_address_audit_comment';
import 'src/javascripts/components/inbox/inbox-element';
import 'src/javascripts/components/locations/locations_audit_comment';
import 'src/javascripts/components/passwordValidator/password_validator';
import 'src/javascripts/components/practice_address/practice_address_audit_comment';
import 'src/javascripts/components/providers/provider_auditing';
import 'src/javascripts/components/side-nav/sideNav';
import 'src/javascripts/utils/ie_placeholder';
import 'src/javascripts/utils/modal_autofocus';
import 'src/javascripts/views/facilities';
import 'src/javascripts/views/primary_sources';
import 'src/javascripts/views/providers';
import 'src/javascripts/views/reports';

// Give webpacker an entry to the scss through this file
import './stylesheets.scss';

// Make sure all rails views and js files have access to required window vars
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.List = List;
window.debounce = debounce; // Expose lodash debounce on the window so rails views can use it

// Copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// Support js file names relative to the javasripts directory, while exluding the specs directory:
var componentRequireContext = require.context("src/javascripts", true, /^((?!\/specs\/|\/redux_containers\/|\/style_guide\/).)*$/);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
