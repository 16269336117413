import React from 'react'
import { connect } from 'react-redux'

export const stateSelect = ({
  domain,
  input,
  meta: { error, dirty }
}) => {
  const stateOptions = domain.us_subdivisions.map((option, index) =>
    <option key={index} value={option.id}>{option.subdivision_name}</option>
  )

  return (
    <div className='search-select'>
      <select className="state_options" name={input.name} value={input.value} onChange={(e) => {input.onChange(e.target.value)}}>
        <option value="">Select state</option>
        {stateOptions}
      </select>
      {dirty && error && <span className="error">{error}</span>}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    domain: state.domain
  }
}

export const connectedStateSelect = connect(mapStateToProps)(stateSelect)
