$(function () {
  if ($('.checklist_templates-requests').length) {
    // Hidden field used to store the parent_id of the request
    const parentField = $("#checklist_template_request_type_parent_id");

    // Label showing where we are adding the new request
    const requestLabel = $("label[for='checklist_template_request_type_request_type_id']");

    // Change the parent_id hidden field and display label when adding
    $(".request-type a.add").on("click", function() {
      const $this = $(this);

      // Hide any existing placeholder items
      $(".choose").addClass("hidden");

      // Unhide the placeholder for this request
      $this.parents("li").first().find("> .request-types > .choose").removeClass("hidden");

      // Set the parent_id hidden field to the id of item we clicked
      parentField.val($this.data("id"));

      // Note in the UI that the request will be added under this one
      requestLabel.text("Add Request Under " + $this.data("name") + ":");
    });

    // Hide the placeholder when cancelling
    $(".choose a").on("click", function(e) {
      e.preventDefault();
      $(this).parents("li").first().addClass("hidden");
      parentField.val("");
      requestLabel.text("Add Top-Level Request:");
    });
  }
});
