import React, { Component } from 'react';

export default class NoResults extends Component {
  render() {
    return(
      <div>
        <div className='well' style={{textAlign: 'center', width: '100%'}}>
          <h5>
            {this.props.children}
          </h5>
        </div>
      </div>
    )
  }
}