import React from 'react'
import profileLinkMap from '../../utils/constants/profile_link_map'

const defaultColumn = {
  row_key: '',
  custom_link_text: ''
}

const buildProfileLink = (id) => `${window.MAIN}/profile/${id}`

const encodeLink = (link) => btoa(link)
export const buildGroupReportLink = (row = {}, column = defaultColumn, trackClick = () => {}) =>
  <a
    target="_blank"
    href={`${window.MAIN}/client/configuration/switch_current_account?action=index&client_id=${row[column.client_id]}&controller=client_accounts&link_path=`+`${encodeLink(window.MAIN+'/workflow/'+row[column.row_key])}`}
    onClick={() => trackClick()}>
    {column.custom_text}
  </a>

export const buildChecklistLink = (row = {}, column = defaultColumn, trackClick = () => {}) =>
  <a
    target="_blank"
    href={`${window.MAIN}/workflow/${row[column.row_key]}`}
    onClick={() => trackClick()}>
    {column.custom_text}
  </a>

export const buildMonitoringLink = (row = {}, column = defaultColumn, trackClick = () => {}) => {
  const profileLink = buildProfileLink(row[column.row_key])
  return (row.source && profileLinkMap[row.source.toLowerCase()])
  ? <a
    target="_blank"
    href={`${profileLink}/${profileLinkMap[row.source.toLowerCase()]}`}
    onClick={() => trackClick()}>
    {column.custom_text}
  </a>
  : <a
    target="_blank"
    title="No monitoring alerts."
    href={profileLink}
    onClick={() => trackClick()}>
    <i className="fa fa-check"></i>
  </a>
}

export const buildExpirationsLink = (row = {}, column = defaultColumn, trackClick = () => {}) => {
  const profileLink = buildProfileLink(row[column.row_key])
  return (row.expirable && profileLinkMap[row.expirable.toLowerCase()])
  ? <a
    target="_blank"
    href={`${profileLink}/${profileLinkMap[row.expirable.toLowerCase()]}`}
    onClick={() => trackClick()}>
    {column.custom_text}
  </a>
  : <a target="_blank" href={profileLink} onClick={() => trackClick()}>{column.custom_text}</a>
}
