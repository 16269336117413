import React from "react"

class FacilityUploadRow extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      status: this.props.uploadRow.status,
      errors: []
    }
  }
  handleChange(e) {
    this.setState({status: e.target.value, errors: []})
    fetch(`${window.origin}/client_facility_uploads/${this.props.uploadRow.id}.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "status": e.target.value
      })
    })
    .then(response =>response.json())
    .then(data  => {
      this.setState({
        errors: data.errors
      })
    })
  }
  render(){
    return (<tr style={ this.state.status === "Pending" ? {"opacity":  1} : {"opacity": .5}}>
      <td>{this.props.uploadRow.filepath}</td>
      <td>{this.props.uploadRow.created_at}</td>
      <td>
        <select
          className="large-form"
          onChange={this.handleChange.bind(this)}
          defaultValue={this.state.status}>
          {
            this.props.statuses.map( (status, idx) =>
              (<option
                key={`${this.props.uploadRow.key}-status-${idx}`}
                value={status}
                >
                  {status}
              </option>)
            )
          }
        </select>
        <div style={{'color': 'red'}}>
          {this.state.errors.join(',')}
        </div>
      </td>
      <td>
        {
          (this.props.uploadRow.download_url) ? (
            <a href={this.props.uploadRow.download_url}>Download</a>
          ): "Download Unavailable"
        }
      </td>
    </tr>)
  }
}
class FacilityUploadsList extends React.Component {
  render() {
    return (
      <div>
        {
          this.props.clientFacilityUploads.length == 0 ? (<p className='tc'>No client uploads found</p>): (
            <table className='table'>
            <thead>
              <tr>
                <th>Filepath</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {this.props.clientFacilityUploads.map( (cfu, idx) => (
                <FacilityUploadRow key={`facility-upload-row-${idx}`} uploadRow={cfu} statuses={this.props.statuses}></FacilityUploadRow>
              ))}
            </tbody>
            </table>
          )
        }
      </div>
    )
  }
}
export default FacilityUploadsList;
