// NOTE: This is a legacy file. Don't add global functions like this.
// import CheckCircleSVG from '-!react-svg-loader!../../../../../assets/svg/icon-successful-approved-neg.svg'

window.showForm = function (elem, objID) {
  $('#' + objID).children('form').show();
  elem.classList.toggle('hide');
  elem.nextElementSibling.classList.toggle('hide');
};
window.hideForm = function (elem, objID) {
  $('#' + objID).children('form').hide();
  elem.classList.toggle('hide');
  elem.previousElementSibling.classList.toggle('hide');
};
