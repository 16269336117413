import React from "react"
import TypeaheadFieldComponent from './TypeaheadFieldComponent'
import MultiSelect from '@khanacademy/react-multi-select'

const displayInput = (
  formField,
  formValues,
  handleValueChange,
  invalidFields,
  findAndReplaceFieldNameForInvalidFieldError,
  application) => {
  const isFieldInvalid = (fieldValue) => invalidFields.hasOwnProperty(fieldValue)

  // Handles value changes if it is an option with secondary values
  const handleChangeWithSecondaryValues = (value, formField) => {
    let newData = [{value: value, key: formField.attribute}]
    const selectedOption = formField.options.find(option => option.value == value)
    if (selectedOption
        && selectedOption.hasOwnProperty('secondary_values')
        && selectedOption.secondary_values.length) {
          selectedOption.secondary_values.forEach(secondary_value => {
            newData = [
              ...newData,
              {key: secondary_value['key'], value: secondary_value['value']}
            ]
          })
    }
    handleValueChange(newData)
  }
  const labelMarkup = (formField) => {
    return (
      <label>{formField.label}{formField.required ? <span className='brand-red f4'>*</span> : null}</label>
    )
  }

  switch (formField.type) {
    case 'text':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
        <input
          className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
          type="text"
          value={formValues[formField.attribute] || ''}
          onChange={(e) => handleValueChange([{value: e.target.value, key: formField.attribute}])}
          pattern={formField.pattern}
          title={`${formField.title}\n${isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}`}
          required={formField.required}
        />
      </div>
    case 'currency':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
          <span className="relative">
            <span className="absolute brand-dark-gray" style={{left: "1px", top: 0}}>$</span>
            <input
              className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
              style={{paddingLeft: "10px"}}
              type="text"
              value={formValues[formField.attribute] ? parseInt(formValues[formField.attribute]).toLocaleString() : 0}
              onChange={(e) => handleValueChange([{value: parseInt(e.target.value.replace(/,/g, '')), key: formField.attribute}])}
              pattern={formField.pattern}
              title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
              required={formField.required}
            />
          </span>
        </div>
    case 'text_area':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
        <textarea
          className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
          type="text"
          value={formValues[formField.attribute] || ''}
          onChange={(e) => handleValueChange([{value: e.target.value, key: formField.attribute}])}
          rows="5"
          cols="50"
          title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
          required={formField.required}
        ></textarea>
      </div>
    case 'select':
      return (
        <div className={`w-100`}>
          {labelMarkup(formField)}
          <div className="brand-select-container w-100">
            <select
              className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
              value={formValues[formField.attribute] || ''}
              onChange={(e) => handleChangeWithSecondaryValues(e.target.value, formField)}
              title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
              required={formField.required}
            >
              <option value="">Select one</option>
              {
                formField.options.map((option, optionIndex) => {
                  return <option
                    value={option.value}
                    onChange={handleValueChange}
                    key={optionIndex}>
                    {option.label}
                  </option>
                })
              }
            </select>
          </div>
        </div>
      )
    case 'email':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
          <input
            className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
            type="email"
            value={formValues[formField.attribute] || ''}
            onChange={(e) => handleValueChange([{value: e.target.value, key: formField.attribute}])}
            title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
            required={formField.required}
          />
      </div>
    case 'date':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
        <input
          className={`brand-input brand-input-fixed-width w-100 ${isFieldInvalid(formField.attribute) ? 'brand-invalid' : ''}`}
          type="date"
          value={formValues[formField.attribute] || ''}
          onChange={(e) => handleValueChange([{value: e.target.value, key: formField.attribute}])}
          title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
          required={formField.required}
        />
      </div>
    case 'multi_select':
      return <div className={`w-100`}>
        {labelMarkup(formField)}
        <div className="brand-multi-select brand-input-fixed-width w-100">
          <MultiSelect
            options={formField.options}
            selected={formValues[formField.attribute] || [] }
            onSelectedChanged={(selected) => handleChangeWithSecondaryValues(selected, formField)}
            valueRenderer={(selected) => selected.length ? `${selected.length} selected` : ' '}
            disableSearch={formField.disable_search}
            hasSelectAll={formField.has_select_all}
            title={isFieldInvalid(formField.attribute) ? findAndReplaceFieldNameForInvalidFieldError(formField.attribute, invalidFields[formField.attribute]).join(' ') : ''}
            required={formField.required}
          />
        </div>
      </div>
    case 'typeahead':
      return <div className={`w-100`}>
          {labelMarkup(formField)}
          <TypeaheadFieldComponent
            value={formValues[formField.attribute] || ''}
            onValueChange={handleValueChange}
            formField={formField}
            application={application}
            isInvalid={isFieldInvalid(formField.attribute)}
            required={formField.required}
          />
      </div>
    case 'hidden':
      // This shouldn't get rendered, but adding this as a fallback
      return <input
          type="hidden"
          value={formValues[formField.attribute] || ''}
        />
  }
}

/*
 * This component displays the values in an editable way for the ViesEditForm
 */

const EditComponent = ({
  className = '',
  formData = [],
  formValues = {},
  application = {},
  handleValueChange = () => {},
  onSave = () => {},
  onCancel = () => {},
  invalidFields = {},
  format = 'page'
}) => {

  const findAndReplaceFieldNameForInvalidFieldError = (invalidFieldName, errors) => {
    let formColumn = formData.find(formColumn => formColumn.fields.find(formField => {
      return formField.attribute === invalidFieldName
    }))
    if (formColumn) {
      let formField = formColumn.fields.find(formField => formField.attribute === invalidFieldName)
      if (formField) {
        return errors.map(error => error.replace(formField.attribute, formField.label))
      }
    }
    return []
  }

  return(
    <div className={className}>
      {
        Object.keys(invalidFields).length
        ? <p className="brand-red">
            Invalid fields detected, please update the highlighted fields and try again.
            <span> {Object.keys(invalidFields).map(invalidFieldName => findAndReplaceFieldNameForInvalidFieldError(invalidFieldName, invalidFields[invalidFieldName])).join(' ')}</span>
          </p>
        : null
      }
      <form
        className={format === 'list' ? '' : ''}
        onSubmit={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onSave()
        }}>
        <div className="">
          {
            formData.map((formColumn, columnIndex) => {
              return (
                <div key={columnIndex} className={`mt4`}>
                  {formColumn.section_name ? <h5>{formColumn.section_name}</h5> : ''}
                  <div className={`grid ${format === 'page' ? 'grid grid-columns3 grid-gap1 mb3' : 'grid grid-columns3 grid-gap1 mb3'}`} >
                    {
                      formColumn.fields.map((formField, fieldIndex) => {
                        return formField.type === 'hidden'
                          ? null
                          : <div
                              className={`self-end ${formField.type === 'text_area' ? 'w-100' : '' }`}
                              key={fieldIndex}>
                              {displayInput(formField, formValues, handleValueChange, invalidFields, findAndReplaceFieldNameForInvalidFieldError, application)}
                            </div>
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="flex justify-end items-center">
          <input type="submit" value="Save" className="app-btn app-btn-mini pa2" />
          <button className="app-btn-secondary app-btn-mini pa2 ml2" onClick={onCancel}>Cancel</button>
        </div>
      </form>
    </div>
  )
}

export default EditComponent
