import { forEach } from 'lodash';

var CredSimple = window.CredSimple || {};

CredSimple.PasswordValidator = (function () {
  'use_strict';

  const VALIDATIONS = {
    eight_chars: /.{8,}/,
    one_uppercase: /[A-Z]+/,
    one_lowercase: /[a-z]/,
    one_digit: /\d+/,
    one_special_char: /[\W_]/
  };

  function _applyListeners() {
    $('#user_current_password, #user_password, #user_password_confirmation').on('input', _validatePassword);
    $('#user_current_password, #user_password, #user_password_confirmation').on('input', _checkConfirmation);
  }

  function _validatePassword(e) {
    $('#user_password, #user_password_confirmation').each(function (i, field) {
      var password = $(field).val();
      forEach(VALIDATIONS, (regexp, validationName) => {
        if (!!(password.match(regexp))) {
          _handleSucces(validationName);
        } else {
          _handleError(validationName);
        };
      });
    });
  }

  function _handleSucces(validationName) {
    $('.validation-' + validationName)
      .removeClass('text-error')
      .addClass('text-success')
      .find('i')
      .removeClass('fa-times brand-red')
      .addClass('fa-check')
    _toggleSubmitButton();
  }

  function _handleError(validationName) {
    $('.validation-' + validationName)
      .removeClass('text-success')
      .addClass('text-error')
      .find('i')
      .removeClass('fa-check')
      .addClass('fa-times brand-red')
    _toggleSubmitButton();
  }

  function _checkConfirmation() {
    var $warning = $('#password-confirmation-error');
    _doPasswordsMatch() ? $warning.hide() : $warning.removeClass('hidden').show();
    _toggleSubmitButton();
  }

  function _doPasswordsMatch() {
    return $('#user_password_confirmation').val() === $('#user_password').val();
  }

  function _toggleSubmitButton() {
    // make sure not to disable a button like 'update' on my_profile page
    var selectors = [
      'input[type="submit"][value="Change my password"]', // expired or reset password
      'input[type="submit"][value="Set password"]'        // invitation accept
    ]
    $(selectors.join()).prop('disabled', _doesFormHaveErrors());
  }

  function _doesFormHaveErrors() {
    return (
      !!($('li.text-error').length) ||          // password errors present
      !!!($('input[type="password"]').val()) || // password is empty
      !(_doPasswordsMatch())                    // confirmation doesnt match
    )
  }

  return {
    initialize: function () {
      // only run on views with password inputs
      if ($("input[type='password']").length) {
        _toggleSubmitButton();
        _applyListeners();
      }
    }
  }
}());

$(document).ready(CredSimple.PasswordValidator.initialize);
