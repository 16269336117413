import React from "react"
import PropTypes from "prop-types"
import * as _ from "lodash"
import FormArray from "./FormArray"
import ViewEditForm from "./ViewEditForm"
import DisclosureForm from "./DisclosureForm"
import AttestationForm from "./AttestationForm"
import IconFlagSVG from '../../../../../assets/svg/icon-issue-flagged.svg'
import IconCheckSVG from '../../../../../assets/svg/icon-successful-approved.svg'


class ReviewAllForms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      application: this.props.application,
      patchEndpoint: `/provider_applications/${this.props.application['id']}`
    }
    this.iconSectionStatusClassList = 'icon absolute left--2'
    this.updateApplicationObject = this.updateApplicationObject.bind(this)
    this.getStatusIconForNonList = this.getStatusIconForNonList.bind(this)
    this.getStatusIconForNormalList = this.getStatusIconForNormalList.bind(this)
    this.getSectionNotApplicable = this.getSectionNotApplicable.bind(this)
    this.getStatusIconForVerifiableSection = this.getStatusIconForVerifiableSection.bind(this)
    this.getStatusIconForAttestation = this.getStatusIconForAttestation.bind(this)
    this.getStatusIconForTraining = this.getStatusIconForTraining.bind(this)
    this.renderHospitalPrivilegesSection = this.renderHospitalPrivilegesSection.bind(this)
  }

  updateApplicationObject(newApplicationObject) {
    const triggerReload = _.get(newApplicationObject, 'attest_date') !== _.get(this.state.application, 'attest_date')
    this.setState({application: newApplicationObject}, () => {
      /* This is a temporary solution for what needs to be solved more thoroughly
       * Currently, the application is submitted from the ERB through a rails route
       * This needs to be brought in closer to the React form code, and likely will
       * require the use of Redux rather than relying solely on rails routing
       */
      if (triggerReload) location.reload()
    })
  }

  getSectionNotApplicable(sectionName, key) {
    // Negate answer because UI displays negative and we store a positive
    if (_.get(this.state.application, `${sectionName}`) && key && _.get(this.state.application, `${sectionName}`).hasOwnProperty(key)) {
      if (_.get(this.state.application, `${sectionName}.${key}`) === "false") {
        return true
      } else if (_.get(this.state.application, `${sectionName}.${key}`) === "true") {
        return false
      }
    }
    return false
  }

  checkIcon(){
    return <IconCheckSVG className="icon-successful-approved icon-success"/>
  }
  flagIcon(){
    return <IconFlagSVG className="icon-issue-flagged icon-important" />
  }

  getStatusIconForNonList(sectionName = null) {
    return (sectionName && _.get(this.state.application, `${sectionName}.percent_complete`) === 1)
      ? this.checkIcon()
      : this.flagIcon()
  }

  getStatusIconForNormalList({
    sectionName = null,
    subsectionName = null,
    listName = null,
    sectionNotApplicable = false,
  }) {
    // Accounts for every scenario in application's current format
    // Is section not applicable?
    if (sectionNotApplicable) return this.checkIcon()
    // Are sectionName and listName defined?
    if (!sectionName || !listName) return this.flagIcon()
    let isComplete = false
    if (subsectionName) {
      // If section should have subsection, do the section, subsection, and list exist and have items in it?
      if (_.get(this.state.application, `${sectionName}.${subsectionName}.${listName}`)
        && _.get(this.state.application, `${sectionName}.${subsectionName}.${listName}`).length) {
        // Are all items in list complete?
        isComplete = this.state.application[sectionName][subsectionName][listName].every(item => {
          return item.percent_complete === 1
        })
      } else {
        return this.flagIcon()
      }
    } else {
      // If section should not have subsection, do the section and list exist and have items in it?
      if (_.get(this.state.application, `${sectionName}.${listName}`)
        && _.get(this.state.application, `${sectionName}.${listName}`).length) {
        // Are all items in list complete?
        isComplete = this.state.application[sectionName][listName].every(item => {
          return item.percent_complete === 1
        })
      } else {
        return this.flagIcon()
      }

    }
    return isComplete ? this.checkIcon() : this.flagIcon()
  }

  getStatusIconForVerifiableSection({sectionName, subsectionName, listName, verificationSubsection, sectionNotApplicable}) {
    // Is section not applicable?
    if (sectionNotApplicable) return this.checkIcon()
    if (!sectionName || !listName) return this.flagIcon()
    const sectionComplete = subsectionName
      ? _.get(this.state.application, `${sectionName}.${subsectionName}.percent_complete`) === 1
      : _.get(this.state.application, `${sectionName}.percent_complete`) === 1

    let isComplete = verificationSubsection
      ? (sectionComplete && _.get(this.state.application, `verification.${listName}.${verificationSubsection}.is_verified`))
      : (sectionComplete && _.get(this.state.application, `verification.${listName}.is_verified`))
    return isComplete ? this.checkIcon() : this.flagIcon()
  }

  getStatusIconForAttestation() {
    return this.state.application['attest_date'] ? this.checkIcon() : this.flagIcon()
  }

  getStatusIconForTraining() {
    // Section is alerted if Work History hasn't been verified
    if (!_.get(this.state.application, `verification.work_history.work_histories.is_verified`)) {
      return this.flagIcon()
    }
    // If Work History is verified and Training has records, ensure that they are complete
    if (_.get(this.state.application, 'training.trainings') && _.get(this.state.application, 'training.trainings').length) {
      return this.getStatusIconForNormalList({
        sectionName: 'training',
        listName: 'trainings'
      })
    }
    // If Work History is verified and Training has no records, it is considered ok
    return this.checkIcon()
  }

  onSubmit() {
    const endpoint = `${window.MAIN}/provider_applications/${this.state.application['provider_id']}/submit.json`
    fetch(endpoint, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'}
    }).then(response => {
      const isValid = response.ok
      response.json().then(data => {
        if (isValid) {
            this.props.updateApplicationObject(data)
            window.location = "/provider-applications/application-submitted"
        }
      })
    })
  }

  renderHospitalPrivilegesSection() {
    return this.props.showHospitalPrivilegesForm
    && <div id="hospital_privileges_form_container" className="relative">
      <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
        {
          this.getStatusIconForNormalList({
            sectionName: 'hospital_privileges',
            listName: 'hospital_privileges',
            sectionNotApplicable: this.getSectionNotApplicable('hospital_privileges.hospital_privileges', 'has_hospital_privileges')
          })
        }
      </div>
      <FormArray
        className="mb4"
        formData={this.props.hospitalPrivilegesFormData}
        application={this.state.application}
        title="Hospital Privileges"
        sectionName="hospital_privileges"
        listName="hospital_privileges"
        patchEndpoint={this.state.patchEndpoint}
        isSinglePageForm={true}
        updateApplicationObject={this.updateApplicationObject}
        hasNotApplicableToggle={true}
        notApplicableOptions={{
          text: "I do not have hospital admitting privileges",
          key: "has_hospital_privileges"
        }}
        disableEditMode={this.props.disableEditMode}
        hideIcons={this.props.hideIcons}
      />
    </div>
  }

  render() {

    return (
      <div className={`pb3 ${this.props.className}`}>
        <div id="profile_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>{this.getStatusIconForNonList('profile')}</div>
          <ViewEditForm
            className="mb4"
            formData={this.props.personalInformationFormData}
            application={this.state.application}
            title="Profile Summary"
            sectionName='profile'
            patchEndpoint={this.state.patchEndpoint}
            format="page"
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            clearSectionOnFieldChangeConfig={{
              fieldToWatch: "provider_type",
              sectionToClear: "certification",
              listToClear: "board_certifications"
            }}
            disableEditMode={this.props.disableEditMode}
          />
        </div>
        <div id="state_license_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'license',
                listName: 'state_licenses'
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.stateLicenseFormData}
            application={this.state.application}
            title="State Licenses"
            sectionName="license"
            listName="state_licenses"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="dea_license_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'license',
                listName: 'dea_licenses',
                sectionNotApplicable: this.getSectionNotApplicable('license', 'has_current_dea_license')
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.deaLicenseFormData}
            application={this.state.application}
            title="DEA Registration"
            sectionName="license"
            listName="dea_licenses"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasNotApplicableToggle={true}
            notApplicableOptions={{
              text: "I do not currently have a DEA registration",
              key: "has_current_dea_license"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="cds_license_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'license',
                listName: 'cds_licenses',
                sectionNotApplicable: this.getSectionNotApplicable('license', 'has_current_cds_license')
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.cdsLicenseFormData}
            application={this.state.application}
            title="CDS Registration"
            sectionName="license"
            listName="cds_licenses"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasNotApplicableToggle={true}
            notApplicableOptions={{
              text: "I do not currently have a CDS registration",
              key: "has_current_cds_license"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="medicare_license_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'license',
                listName: 'medicare_licenses',
                sectionNotApplicable: this.getSectionNotApplicable('license', 'has_current_medicare_license')
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.medicareLicenseFormData}
            application={this.state.application}
            title="Medicare Numbers"
            sectionName="license"
            listName="medicare_licenses"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasNotApplicableToggle={true}
            notApplicableOptions={{
              text: "I do not currently have a Medicare Number",
              key: "has_current_medicare_license"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="medicaid_license_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'license',
                listName: 'medicaid_licenses',
                sectionNotApplicable: this.getSectionNotApplicable('license', 'has_current_medicaid_license')
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.medicaidLicenseFormData}
            application={this.state.application}
            title="Medicaid Numbers"
            sectionName="license"
            listName="medicaid_licenses"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasNotApplicableToggle={true}
            notApplicableOptions={{
              text: "I do not currently have a Medicaid Number",
              key: "has_current_medicaid_license"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="education_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'education',
                listName: 'schools',
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.educationFormData}
            application={this.state.application}
            title="Education"
            sectionName="education"
            listName="schools"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="training_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForTraining()
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.trainingFormData}
            application={this.state.application}
            title="Training"
            sectionName="training"
            listName="trainings"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasVerificationDisclaimer={true}
            verificationDisclaimerOptions={{
              message: "Fellowship is required if work history is less than 5 years.",
              verificationPath: "verification.work_history.work_histories.is_verified"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="board_certification_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'certification',
                listName: 'board_certifications',
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.boardCertificationFormData}
            application={this.state.application}
            title="Specialty and Board Certification"
            sectionName="certification"
            listName="board_certifications"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            dependentOnPropertyPath="profile.provider_type"
            missingDependentPropertyErrorMessage="Provider type must be saved in Personal Information to add Board / Specialty information."
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="work_history_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              // Should use real listName?
              this.getStatusIconForVerifiableSection({
                sectionName: 'work_history',
                listName: 'work_history',
                verificationSubsection: 'work_histories'
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.workHistoryFormData}
            application={this.state.application}
            title="Work History"
            sectionName="work_history"
            listName="work_histories"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasVerificationDisclaimer={true}
            verificationDisclaimerOptions={{
              message: "Required to determine compliance.",
              verificationPath: "verification.work_history.work_histories.is_verified"
            }}
            verificationObjPath="verification.work_history.work_histories"
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="time_gap_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              // Should use real listName?
              this.getStatusIconForVerifiableSection({
                sectionName: 'work_history',
                listName: 'work_history',
                verificationSubsection: 'work_histories'
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.timeGapFormData}
            application={this.state.application}
            title="Time Gap Explanations"
            sectionName="work_history"
            listName="gap_explanations"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasVerificationDisclaimer={true}
            verificationDisclaimerOptions={{
              message: "Required if there are gaps in work history greater than 6 months.",
              verificationPath: "verification.work_history.work_histories.is_verified"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="practice_locations_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForNormalList({
                sectionName: 'practice_detail',
                subsectionName: 'practice',
                listName: 'practice_locations',
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.practiceLocationFormData}
            application={this.state.application}
            title="Practice Locations"
            sectionName="practice_detail"
            subsectionName="practice"
            listName="practice_locations"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasVerificationDisclaimer={true}
            verificationDisclaimerOptions={{
              message: "Required to determine compliance.",
              verificationPath: "verification.work_history.work_histories.is_verified"
            }}
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        <div id="malpractice_insurance_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              // Should use listName?
              this.getStatusIconForVerifiableSection({
                sectionName: 'practice_detail',
                subsectionName: 'malpractice',
                listName: 'malpractice_insurance',
                sectionNotApplicable: this.getSectionNotApplicable('practice_detail.malpractice', 'has_malpractice_insurance')
              })
            }
          </div>
          <FormArray
            className="mb4"
            formData={this.props.malpracticeInsuranceFormData}
            application={this.state.application}
            title="Malpractice Insurance"
            sectionName="practice_detail"
            subsectionName="malpractice"
            listName="malpractice_insurances"
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            hasNotApplicableToggle={true}
            notApplicableOptions={{
              text: "I am not required to carry malpractice insurance",
              key: "has_malpractice_insurance"
            }}
            verificationObjPath="verification.malpractice_insurance"
            disableEditMode={this.props.disableEditMode}
            hideIcons={this.props.hideIcons}
          />
        </div>
        {this.renderHospitalPrivilegesSection()}
        <div id="disclosure_questions_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForVerifiableSection({
                sectionName: 'disclosure',
                listName: 'disclosure_questions'
              })
            }
          </div>
          <DisclosureForm
            className="mb4"
            application={this.state.application}
            disclosureQuestions={this.props.disclosureQuestions}
            patchEndpoint={this.state.patchEndpoint}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            containingElementId="disclosure_questions_form_container"
            disableEditMode={this.props.disableEditMode}
            isDataEntryUser={this.props.isDataEntryUser}
            disclosureFormTypes={this.props.disclosureFormTypes}
          />
        </div>
        <div id="attestation_form_container" className="relative">
          <div className={`${this.iconSectionStatusClassList} ${this.props.hideIcons ? 'dn' : ''}`}>
            {
              this.getStatusIconForAttestation()
            }
          </div>
          <AttestationForm
            className="mb4"
            application={this.state.application}
            isSinglePageForm={true}
            updateApplicationObject={this.updateApplicationObject}
            disableEditMode={this.props.disableEditMode}
            isDataEntryUser={this.props.isDataEntryUser}
            attestDateThreshold={this.props.attestDateThreshold}
          />
        </div>
      </div>
    )
  }
}

ReviewAllForms.propTypes = {
  className: PropTypes.string,
  application: PropTypes.object,
  personalInformationFormData: PropTypes.array,
  stateLicenseFormData: PropTypes.array,
  deaLicenseFormData: PropTypes.array,
  cdsLicenseFormData: PropTypes.array,
  medicareLicenseFormData: PropTypes.array,
  medicaidLicenseFormData: PropTypes.array,
  educationFormData: PropTypes.array,
  trainingFormData: PropTypes.array,
  boardCertificationFormData: PropTypes.array,
  workHistoryFormData: PropTypes.array,
  timeGapFormData: PropTypes.array,
  practiceLocationFormData: PropTypes.array,
  malpracticeInsuranceFormData: PropTypes.array,
  disclosureQuestions: PropTypes.array,
  hospitalPrivilegesFormData: PropTypes.array,
  showHospitalPrivilegesForm: PropTypes.bool,
  disableEditMode: PropTypes.bool,
  hideIcons: PropTypes.bool,
  isDataEntryUser: PropTypes.bool,
  disclosureFormTypes: PropTypes.array,
  attestDateThreshold: PropTypes.number
}

ReviewAllForms.defaultProps = {
  className: "",
  application: {},
  personalInformationFormData: [],
  stateLicenseFormData: [],
  deaLicenseFormData: [],
  cdsLicenseFormData: [],
  medicareLicenseFormData: [],
  medicaidLicenseFormData: [],
  educationFormData: [],
  trainingFormData: [],
  boardCertificationFormData: [],
  workHistoryFormData: [],
  timeGapFormData: [],
  practiceLocationFormData: [],
  malpracticeInsuranceFormData: [],
  disclosureQuestions: [],
  hospitalPrivilegesFormData: [],
  showHospitalPrivilegesForm: false,
  disableEditMode: false,
  hideIcons: false,
  isDataEntryUser: false,
  disclosureFormTypes: [],
  attestDateThreshold: null
}

export default ReviewAllForms
