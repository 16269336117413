const React = require("react")
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const ListChart = ({
  className,
  title,
  type,
  data,
  options,
  seriesOptions,
  chartDriver = Highcharts
}) => {
  const highchartOptions = {
    title: {
      text: title
    },
    chart: {
      height: '200px',
      width: '300',
      spacingLeft: 11
    },
    series: [{
      type: type,
      keys: ['y', 'name'],
      data,
      ...seriesOptions
    }],
    ...options
  }

  return (
    <div className={`${className}`}>
      <HighchartsReact
        highcharts={chartDriver}
        options={highchartOptions}
      />
    </div>
  )
}

export default ListChart