$(function () {
  $(document).on("keyup", "#home_address_audit_comment", function (e) {
    let $this = $(this);
    let value = $this.val().trim();
    let $parent = $this.closest('.home_address_form');

    // Set the hidden field value to the trimmed value
    // let $input = $parent.find("input.audit_comment[type='hidden']");
    // $input.val(value);

    // If an audit comment is required, conditionally enable/disable
    // the delete button and change its style to reflect the state
    if ($this.prop('required')) {
      let button = $parent.find("button[type='submit']")

      if (value === '') {
        button.attr('disabled', 'disabled');
        // button.removeClass('app-btn');
        button.addClass('btn-disabled');
      } else {
        button.removeAttr('disabled');
        // button.addClass('app-btn');
        button.removeClass('btn-disabled');
      }
    }
  });
});
